import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import { ArrowForwardOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { Loader } from 'core/components/Loader'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import { translate } from 'core/_helpers/translate'
import proposalSchema from '_schema/proposal'
import routes from 'pages/applicant/FinishedProposals/Preview/routes'
import inProgressRoutes from 'pages/applicant/FinishedProposals/routes'
import proposalRoutes from 'pages/applicant/Proposals/routes'
import { CustomDialog } from 'core/components/Dialog'
import {
  RATE_FAILED,
  RATE_PASSED,
  SUBSTANTIVE_RATE_STATUS_PASSED,
} from '_schema/contestProposalRateGuidelines'
import { ButtonRight } from 'core/components/buttons/button-right'
import schemaFormalRates from '_schema/proposalFormalRate'
import schemaSubstantiveRates from '_schema/proposalSubstantiveRate'
import { ReactComponent as SuccessIcon } from 'theme/icons/success.svg'
import { ReactComponent as WarningIcon } from 'theme/icons/warrning.svg'
import { downloadFile } from '_helpers/downloadFile'
import { downloadFileFromFile } from '../../../../../_helpers/downloadFileFromFile'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 60,
    paddingBottom: 60,
    paddingRight: 100,
    paddingLeft: 100,
    backgroundColor: 'white',
    fontSize: 16,
    color: theme.palette.primary.main,
    '@media (max-width: 1200px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  header: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 30,
  },
  content: {
    '&> *': {
      marginTop: 30,
    },
  },
  bolded: {
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  link: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& svg': {
      marginLeft: 10,
    },
  },
  button_wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  rowsvg: {
    position: 'absolute',
    left: -20,
    top: 3,
  },
}))

export const Sidebar = ({
  proposalUuid,
  contestUuid,
  collectingEndDate,
  applicationDate,
  formalRatingDateTo,
  expertRatingDateTo,
  contractSigningDate,
  contractSignedFile,
  contestTrainings,
  acceptationStatus,
  proposalVisitation,
  substantiveRateStatus,
  formalRateStatus,
  contractStatus,
  proposalTrainings,
  withEditButton = true,
  proposalNumber = '',
}) => {
  const classes = useStyles()

  const [isRevertingProposal, setIsRevertingProposal] = useState(false)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(state => !state)
  }

  const history = useHistory()

  const handleRevertProposal = () => {
    setIsRevertingProposal(true)

    fetchDataHandleAuthError(
      proposalSchema.endpoint_applicant_apply.replace(':id', proposalUuid),
      'PUT',
      {
        body: JSON.stringify({
          applicationStatus: proposalApplicationStatuses.DRAFT,
        }),
      },
      () => {
        history.push(
          proposalRoutes().edit.path.replace(':contest_id', contestUuid)
        )
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setIsRevertingProposal(false)
      }
    )
  }

  const [formalRates, setFormalRates] = useState([])
  const [substantiveRates, setSubstantiveRates] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (formalRateStatus) {
      const url = `${schemaFormalRates.applicantEndpoint}?proposal.uuid=${proposalUuid}`
      fetchDataHandleAuthError(url, 'GET', { signal }, resp => {
        setFormalRates(resp['hydra:member'])
      })
    }
    return () => controller.abort()
  }, [proposalUuid, formalRateStatus])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (substantiveRateStatus === SUBSTANTIVE_RATE_STATUS_PASSED) {
      const url = `${schemaSubstantiveRates.endpointApplicant}?proposal.uuid=${proposalUuid}`
      fetchDataHandleAuthError(url, 'GET', { signal }, resp => {
        const groupBy = items =>
          items.reduce(
            (result, item) => ({
              ...result,
              [item.rateGuideline.uuid]: [
                ...(result[item.rateGuideline.uuid] || []),
                item,
              ],
            }),
            {}
          )
        const obj = groupBy(resp['hydra:member'])

        const arrayNew = Object.keys(obj).map(el => {
          const rates = obj[el]
          const rate = {
            points: 0,
          }
          rate.guideline = rates[0].rateGuideline
          rates.forEach(el => {
            rate.points += el.points
          })
          rate.points = rate.points / rates.length
          return rate
        })

        setSubstantiveRates(arrayNew)
      })
    }
    return () => controller.abort()
  }, [proposalUuid, substantiveRateStatus])

  const [openFormalRate, setOpenFormalRate] = useState(false)
  const [openSubstantiveRate, setOpenSubstantiveRate] = useState(false)

  const handleChangeDialog = type => {
    switch (type) {
      case 'formal':
        setOpenFormalRate(prevState => !prevState)
        break
      case 'substantive':
        setOpenSubstantiveRate(prevState => !prevState)
        break
      default:
        break
    }
  }

  const handlePdfDownlad = () => {
    const iri = `${proposalSchema.endpoint_pdf_download}${proposalUuid}`
    downloadFile(iri, `wniosek-${proposalNumber || proposalUuid}.pdf`)
  }

  const handleDownload2 = id => {
    downloadFileFromFile(id)
  }

  return (
    <div className={classes.root}>
      <h3 className={classes.header}>
        {translate('T_MODULE_PROPOSALS_DATES_AND_STATUS')}
      </h3>
      <div className={classes.content}>
        <div>
          {translate('T_MODULE_PROPOSALS_COLLECTING_END_DATE')}:{' '}
          {collectingEndDate && moment(collectingEndDate).format(DATE_FORMAT)}
          <div>
            <p className={classes.bolded}>
              {translate('T_MODULE_PROPOSALS_COLLECTED_AT')}:{' '}
              {applicationDate && moment(applicationDate).format(DATE_FORMAT)}
            </p>
          </div>
        </div>
        <div>
          {translate('T_MODULE_PROPOSALS_FORMAL_RATING')}:{' '}
          {formalRatingDateTo && moment(formalRatingDateTo).format(DATE_FORMAT)}
          {formalRateStatus && (
            <div className={classes.row}>
              <p className={classes.bolded}>
                {translate('Ocena wystawiona')}:{' '}
                {applicationDate && moment(applicationDate).format(DATE_FORMAT)}
              </p>
              {formalRates.length > 0 && (
                <>
                  <ButtonRight
                    text={'Zobacz'}
                    onClick={() => handleChangeDialog('formal')}
                  />
                  <CustomDialog
                    open={openFormalRate}
                    handleToogle={() => handleChangeDialog('formal')}
                    title={'T_MODULE_PROPOSALS_FORMAL_RATING'}
                    // secondTitle={moment(formalRates[0].createAt)}
                    component={() => (
                      <>
                        {formalRates.map(el => (
                          <Grid
                            key={el.uuid}
                            container
                            spacing={3}
                            alignItems={'center'}
                          >
                            <Grid item xs={1}>
                              {el.rate === RATE_PASSED && (
                                <>
                                  <Tooltip title={translate('Ocena pozytywna')}>
                                    <SuccessIcon />
                                  </Tooltip>
                                </>
                              )}
                              {el.rate === RATE_FAILED && (
                                <>
                                  <Tooltip title={translate('Ocena negatywna')}>
                                    <WarningIcon />
                                  </Tooltip>
                                </>
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant={'body2'} color={'primary'}>
                                {moment(el.createdAt).format(DATE_FORMAT)}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                variant={'subtitle1'}
                                color={'primary'}
                              >
                                {el.rateGuideline.title}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div>
          {translate('T_MODULE_PROPOSALS_EXPERTS_RATING')}:{' '}
          {expertRatingDateTo && moment(expertRatingDateTo).format(DATE_FORMAT)}
          {substantiveRateStatus === SUBSTANTIVE_RATE_STATUS_PASSED && (
            <div className={classes.row}>
              <p className={classes.bolded}>
                {translate('Ocena wystawiona')}:{' '}
                {applicationDate && moment(applicationDate).format(DATE_FORMAT)}
              </p>
              <ButtonRight
                text={'Zobacz'}
                onClick={() => handleChangeDialog('substantive')}
              />
              <CustomDialog
                open={openSubstantiveRate}
                handleToogle={() => handleChangeDialog('substantive')}
                title={'T_MODULE_PROPOSALS_EXPERTS_RATING'}
                component={() => (
                  <>
                    {substantiveRates.map((el, i) => (
                      <Grid key={i} container spacing={3} alignItems={'center'}>
                        <Grid item xs={4}>
                          <Typography variant={'h3'} color={'primary'}>
                            {el.guideline.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant={'body2'} color={'primary'}>
                            {el.guideline.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant={'body2'} color={'primary'}>
                            {el.guideline.pointsMin} - {el.guideline.pointsMax}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant={'h3'} color={'primary'}>
                            {el.points.toFixed(0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              />
            </div>
          )}
        </div>

        {acceptationStatus === 'SUBSIDIZED' &&
          contestTrainings?.map((e, index) => (
            <div key={e['@id']}>
              {proposalTrainings !== undefined &&
              proposalTrainings[index] !== undefined &&
              proposalTrainings[index].inquiryDate ? (
                <div>
                  <div>
                    Szkolenie zamkniete: {moment(e.date).format(DATE_FORMAT)}
                  </div>
                  <div className={classes.row}>
                    <div className={classes.bolded}>
                      {translate('Ankieta przesłana')}:{' '}
                      {moment(proposalTrainings[index]?.inquiryDate).format(
                        DATE_FORMAT
                      )}
                      {proposalTrainings[index].resultStatus === 'FAILED' && (
                        <Typography variant={'body1'} color={'error'}>
                          {translate('T_PROPOSAL_TRAINING_RESULT_FAILED')}
                        </Typography>
                      )}
                      {proposalTrainings[index].resultStatus === 'PASSED' && (
                        <Typography variant={'body1'} color={'secondary'}>
                          {translate('T_PROPOSAL_TRAINING_RESULT_PASSED')}
                        </Typography>
                      )}
                    </div>
                    <Link
                      to={routes()
                        .training.path.replace(':id', proposalUuid)
                        .replace(':index', `training-${index + 1}`)} // // ugly url
                      className={classes.link}
                    >
                      {translate('Zobacz')} <ArrowForwardOutlined />
                    </Link>
                  </div>
                </div>
              ) : (
                <div className={classes.row}>
                  {e.notificationSend && moment().isSameOrBefore(e.date) && (
                    <>
                      <svg
                        className={classes.rowsvg}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <mask id="path-1-inside-1_504_1613" fill="white">
                          <path d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z" />
                        </mask>
                        <path
                          d="M7.75 10V9.25H6.25V10H7.75ZM6.25 10.01V10.76H7.75V10.01H6.25ZM6.25 3.5V7.5H7.75V3.5H6.25ZM6.25 10V10.01H7.75V10H6.25ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H-1.5C-1.5 11.6944 2.30558 15.5 7 15.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V15.5C11.6944 15.5 15.5 11.6944 15.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H15.5C15.5 2.30558 11.6944 -1.5 7 -1.5V1.5ZM7 -1.5C2.30558 -1.5 -1.5 2.30558 -1.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V-1.5Z"
                          fill="#B72030"
                          mask="url(#path-1-inside-1_504_1613)"
                        />
                      </svg>
                    </>
                  )}
                  <div>
                    Szkolenie zamkniete: {moment(e.date).format(DATE_FORMAT)}
                  </div>
                  {/*<Link
                    to={routes()
                      .training.path.replace(':id', proposalUuid)
                      .replace(':index', `training-${index + 1}`)} // // ugly url
                    className={classes.link}
                  >
                    {translate('Zapisz się')} <ArrowForwardOutlined />
                  </Link>*/}
                </div>
              )}
            </div>
          ))}
        {proposalVisitation && (
          <div className={classes.row}>
            <div>Planowana wizyta: </div>
            <Link
              to={inProgressRoutes().visit.path.replace(':id', proposalUuid)}
              className={classes.link}
            >
              {translate('T_GENERAL_SEE')} <ArrowForwardOutlined />
            </Link>
          </div>
        )}

        {contractStatus && (
          <>
            <div className={classes.row}>
              <div>
                {translate('T_MODULE_PROPOSALS_CONTRACT')}:{' '}
                {contractSigningDate &&
                  moment(contractSigningDate).format(DATE_FORMAT)}
              </div>
              {contractSignedFile ? (
                <ButtonDownload
                  text={'Zobacz'}
                  // path={`${process.env.REACT_APP_API_ENTRYPOINT}${contractSignedFile.url}`}
                  onClick={() => handleDownload2(contractSignedFile.uuid)}
                />
              ) : (
                <Link
                  to={routes().contract.path.replace(':id', proposalUuid)}
                  className={classes.link}
                >
                  {translate('T_GENERAL_SEE')} <ArrowForwardOutlined />
                </Link>
              )}
            </div>
          </>
        )}
        {withEditButton && (
          <div className={classes.button_wrapper}>
            {isRevertingProposal ? (
              <Loader align="center" marginTop={10} />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                disabled={isRevertingProposal}
                onClick={handleOpen}
              >
                {translate('T_MODULE_PROPOSALS_CHANGE_APPLIED_PROPOSAL')}
              </Button>
            )}
          </div>
        )}
        <CustomDialog
          handleToogle={handleOpen}
          open={open}
          title={'Czy na pewno chcesz zmienić złożony wniosek?'}
          component={() => (
            <Typography variant={'body1'} color={'primary'}>
              {translate(
                'Pamiętaj, że zostanie on cofnięty do edycji i koniecznym będzie jego ponowne skuteczne złożenie przed zakończeniem terminu naboru.'
              )}
            </Typography>
          )}
          confirmedButton={true}
          onConfirm={handleRevertProposal}
          confirmedText={'Zmień'}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonDownload
            text={'pobierz wniosek jako pdf'}
            onClick={handlePdfDownlad}
          />
        </div>
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  proposalUuid: PropTypes.string.isRequired,
  contestUuid: PropTypes.string.isRequired,
  collectingEndDate: PropTypes.string,
  applicationDate: PropTypes.string,
  formalRatingDateTo: PropTypes.string,
  contractSigningDate: PropTypes.string,
  contractSignedFile: PropTypes.object,
  contestTrainings: PropTypes.array,
  proposalTrainings: PropTypes.array,
  proposalVisitation: PropTypes.string,
  withEditButton: PropTypes.bool,
  contractStatus: PropTypes.string,
}
