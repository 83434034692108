import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'
import { Grid, Typography } from '@material-ui/core'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(3),
  },
  help: {
    marginBottom: theme.spacing(3),
    color: theme.palette.disabledDark,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
}))

export const RealizationDateTimeBlock = ({ data, values, contest }) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.REALIZATION_DATE_TIME, data),
    [data]
  )

  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.row}>
      <Grid item md={8}>
        <Typography variant={'subtitle1'} color={'primary'}>
          {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_RANGE')}:{' '}
          {contest?.projectRealizationDateFrom &&
            moment(contest?.projectRealizationDateFrom).format(DATE_FORMAT)}
          {' - '}
          {contest?.projectRealizationDateTo &&
            moment(contest?.projectRealizationDateTo).format(DATE_FORMAT)}
        </Typography>
        <div className={classes.flex}>
          <div className={classes.flex}>
            <Typography variant={'h3'} color={'primary'}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_FROM')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {values?.project_realization_date_from &&
                moment(values?.project_realization_date_from).format(
                  DATE_FORMAT
                )}
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography variant={'h3'} color={'primary'}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_TO')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {values?.project_realization_date_to &&
                moment(values?.project_realization_date_to).format(DATE_FORMAT)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item md={4}>
        {mapping.inputs.help && (
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.help}
          >
            {mapping.inputs.help}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

RealizationDateTimeBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  contest: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
  }),
}
