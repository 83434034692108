import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { BudgetItemType } from './BudgetItemType'
import { isBudgetCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isBudgetCollectionInvalid'
import { generateBudgetCollectionRenderError } from 'pages/applicant/Proposals/_helpers/generateBudgetCollectionRenderError'
import { useBudgetCollectionTypeStyles } from './BudgetCollectionType.styles'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'
import { HelpModule } from 'components/HelpModule'
import { isEnableBudgetMenageAdv } from '_helpers/isEnableBudgetMenageAdv'
import { IconButton } from '@material-ui/core'
import { useContestBudgetCategories } from '_helpers/useContestBudgetCategories'

const ValidateCategory = ({
  percent,
  categoryId,
  setErrorHandle,
  subsidyAmount,
  state,
  classes,
  name,
}) => {
  const [error, setError] = useState(false)
  useEffect(() => {
    const subsidyAmountSumCategory = state.values.reduce((sum, budget) => {
      if (budget.categoryId === categoryId) {
        return sum + (budget.subsidyAmount || 0)
      }
      return sum
    }, 0)
    const subsidyAmountSumCategoryPercent =
      (subsidyAmountSumCategory / subsidyAmount) * 100

    if (subsidyAmountSumCategoryPercent > percent) {
      setError(true)
    } else {
      setError(false)
    }
  }, [subsidyAmount, state.values, categoryId, percent])

  useEffect(() => {
    setErrorHandle(name, error)
  }, [error, name, setErrorHandle])

  return (
    <>
      {error && (
        <small className={classes.error}>
          Błąd! Maksymalna kwota przeznaczona na tę kategorię to{' '}
          {subsidyAmount * (percent / 100)} PLN.
        </small>
      )}
    </>
  )
}

export const BudgetCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  contest,
  isFormSubmitted,
  sidebarNodeRef,
  subsidyAmount,
}) => {
  const budgetCategories = useContestBudgetCategories(contest.uuid)
  const [state, setState] = useState({
    values: value,
    errors: !value || !value.length ? [{}] : Array(value.length).fill({}),
    renderError: !value || !value.length ? [{}] : Array(value.length).fill({}),
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  useEffect(() => {
    if (
      (!value || !value.length) &&
      budgetCategories.data.length > 0 &&
      state.init
    ) {
      setState(state => ({
        ...state,
        values: budgetCategories.data.map(category => ({
          categoryId: category.id,
          contestBudgetCategory: category['@id'],
        })),
      }))
    }
  }, [budgetCategories, state.init, value])

  const handleAdd = useCallback(
    (categoryId = null, categoryIri = null) => {
      let newBudget = {}
      console.log('categoryId', categoryId)
      if (categoryId) {
        newBudget = { categoryId: categoryId }
      }
      if (categoryIri) {
        newBudget = { ...newBudget, contestBudgetCategory: categoryIri }
      }
      setState(state => ({
        ...state,
        values: [...state.values, newBudget],
        errors: [...state.errors, {}],
        renderError: [...state.renderError, {}],
      }))
    },
    [setState]
  )

  const handleDelete = useCallback(
    index => () => {
      setState(state => ({
        ...state,
        values: state.values?.filter((item, i) => i !== index),
        errors: state.errors?.filter((item, i) => i !== index),
        renderError: state.renderError?.filter((item, i) => i !== index),
        isInvalid: isBudgetCollectionInvalid(
          state.errors?.filter((item, i) => i !== index)
        ),
      }))
    },
    [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateBudgetCollectionRenderError(state.errors, true),
    }))
  }, [setState, state.isFormSubmitted])

  const ownContributionAmountSum = state.values.reduce(
    (sum, budget) => sum + (budget.ownContributionAmount || 0),
    0
  )

  const subsidyAmountSum = state.values.reduce(
    (sum, budget) => sum + (budget.subsidyAmount || 0),
    0
  )

  const totalSum = ownContributionAmountSum + subsidyAmountSum

  const classes = useBudgetCollectionTypeStyles()

  const ownContributionPercent =
    ownContributionAmountSum && subsidyAmountSum
      ? (
          ownContributionAmountSum /
          (ownContributionAmountSum + subsidyAmountSum)
        ).toFixed(2)
      : 0

  const minOwnContributionPercent = (
    contest.ownContributionMinAmount / 100
  ).toFixed(2)

  const leftRequiredOwnContributionAmount =
    ownContributionPercent < minOwnContributionPercent
      ? Math.ceil(
          (minOwnContributionPercent * subsidyAmountSum) /
            (1 - minOwnContributionPercent)
        ) - ownContributionAmountSum
      : 0

  useEffect(() => {
    if (leftRequiredOwnContributionAmount > 0) {
      setState(state => ({
        ...state,
        isInvalid: true,
      }))
    }
  }, [leftRequiredOwnContributionAmount])

  // funkcja która sprawdza czy kwota jest liczbą całkowitą
  const isInteger = value => {
    return !Number.isInteger(value)
  }

  const [enableBudgetMenage, setEnableBudgetMenage] = useState(true)

  useEffect(() => {
    setEnableBudgetMenage(isEnableBudgetMenageAdv(value, budgetCategories.data))
  }, [value, budgetCategories])

  const HeaderHtml = () => (
    <div className={classes.header}>
      <div className={classes.header_number}></div>
      <div className={classes.header_title}>
        {translate('T_MODULE_PROPOSALS_BUDGET_TITLE')}
        {contest.budgetTypeHint && (
          <HelpModule
            title={translate('T_MODULE_PROPOSALS_BUDGET_TITLE')}
            description={contest.budgetTypeHint}
          />
        )}
      </div>
      <div className={classes.header_calculation_method}>
        {translate('T_MODULE_PROPOSALS_BUDGET_CALCULATION_METHOD')}
        {contest.budgetCalculationMethodHint && (
          <HelpModule
            title={translate('T_MODULE_PROPOSALS_BUDGET_CALCULATION_METHOD')}
            description={contest.budgetCalculationMethodHint}
          />
        )}
      </div>
      <div className={classes.header_own_contribution_amout}>
        {translate('T_MODULE_PROPOSALS_BUDGET_OWN_CONTRIBUTION_AMOUNT')}
        {contest.budgetOwnContributionHint && (
          <HelpModule
            title={translate(
              'T_MODULE_PROPOSALS_BUDGET_OWN_CONTRIBUTION_AMOUNT'
            )}
            description={contest.budgetOwnContributionHint}
          />
        )}
      </div>
      <div className={classes.header_subsidy_amount}>
        {translate('T_MODULE_PROPOSALS_BUDGET_SUBSIDY_AMOUNT')}
        {contest.budgetSubsidyHint && (
          <HelpModule
            title={translate('T_MODULE_PROPOSALS_BUDGET_SUBSIDY_AMOUNT')}
            description={contest.budgetSubsidyHint}
          />
        )}
      </div>
    </div>
  )

  const setErrorHandle = useCallback(
    (name, value) => {
      setError(name, value)
    },
    [setError]
  )

  const FooterHtml = ({ categoryId }) => {
    const subsidyAmountSumCategory = state.values.reduce((sum, budget) => {
      if (budget.categoryId === categoryId) {
        return sum + (budget.subsidyAmount || 0)
      }
      return sum
    }, 0)

    const ownAmountSumCategory = state.values.reduce((sum, budget) => {
      if (budget.categoryId === categoryId) {
        return sum + (budget.ownContributionAmount || 0)
      }
      return sum
    }, 0)
    return (
      <div className={classes.header}>
        <div className={classes.header_number}></div>
        <div className={classes.header_title}></div>
        <div className={classes.header_calculation_method}>Razem</div>
        <div className={classes.header_own_contribution_amout}>
          {ownAmountSumCategory} PLN
        </div>
        <div className={classes.header_subsidy_amount}>
          {subsidyAmountSumCategory} PLN
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.description}>
          {contest.budgetDescriptionHint && contest.budgetDescriptionHint}
        </div>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_BUDGET_COSTS')}
        </div>
        <div>
          {!enableBudgetMenage ? (
            <div>
              <HeaderHtml />
              {state.values.map((item, index) => (
                <div className={classes.container} key={index}>
                  <BudgetItemType
                    index={index}
                    values={state.values?.[index]}
                    errors={state.errors[index]}
                    renderError={state.renderError[index]}
                    globalRenderError={renderError}
                    setState={setState}
                    disabled={disabled}
                    enableBudgetMenage={enableBudgetMenage}
                    subsidyAmountSum={subsidyAmountSum}
                  />
                  <div className={classes.sidebar}>
                    {index > 0 && (
                      <IconButton
                        onClick={handleDelete(index)}
                        disabled={disabled}
                        size={'medium'}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </div>
                </div>
              ))}
              <ButtonPlus
                text={translate('T_MODULE_PROPOSALS_ADD_BUDGET')}
                disabled={disabled}
                onClick={() => handleAdd()}
              />
            </div>
          ) : (
            <div>
              {budgetCategories.isFetching && <div>Ładowanie...</div>}
              {budgetCategories.error && <div>Błąd pobierania danych</div>}
              {!budgetCategories.isFetching && !budgetCategories.error && (
                <>
                  {budgetCategories.data.map(category => (
                    <div key={category.uuid}>
                      <div className={classes.subtitle}>
                        {category.title}{' '}
                        {category.percentage > 0 && (
                          <small>
                            (Maks {category.percentage}% całkowitej kwoty
                            wnioskowanej do FCP)
                          </small>
                        )}
                        {category.percentage > 0 && (
                          <ValidateCategory
                            categoryId={category.id}
                            percent={category.percentage}
                            setErrorHandle={setErrorHandle}
                            state={state}
                            subsidyAmount={subsidyAmount}
                            classes={classes}
                            name={name}
                          />
                        )}
                      </div>
                      <HeaderHtml key={category.id} />
                      {state.values.map((item, index) => (
                        <>
                          {item.categoryId === category.id && (
                            <div className={classes.container} key={index}>
                              <BudgetItemType
                                index={index}
                                values={state.values?.[index]}
                                errors={state.errors[index]}
                                renderError={state.renderError[index]}
                                globalRenderError={renderError}
                                setState={setState}
                                disabled={disabled}
                                enableBudgetMenage={enableBudgetMenage}
                                subsidyAmountSum={subsidyAmountSum}
                              />
                              <div className={classes.sidebar}>
                                {index > 0 && (
                                  <IconButton
                                    onClick={handleDelete(index)}
                                    disabled={disabled}
                                    size={'medium'}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      <FooterHtml categoryId={category.id} />
                      <ButtonPlus
                        text={
                          translate('T_MODULE_PROPOSALS_ADD_BUDGET') +
                          ' - ' +
                          category.title
                        }
                        disabled={disabled}
                        onClick={() => handleAdd(category.id, category['@id'])}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={classes.title}>
        {translate('T_MODULE_PROPOSALS_BUDGET_SUM')}: {totalSum}{' '}
        {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
      </div>
      <div className={classes.summary}>
        <div
          className={clsx(isInteger(subsidyAmountSum) ? classes.error : null)}
        >
          <div>{translate('T_MODULE_PROPOSALS_BUDGET_SUBSIDY_AMOUNT')}</div>
          {subsidyAmountSum} {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
        <div>
          <div>
            {translate('T_MODULE_PROPOSALS_BUDGET_OWN_CONTRIBUTION_AMOUNT')}
          </div>
          {ownContributionAmountSum}{' '}
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
        <div>
          <div>
            {translate(
              'T_MODULE_PROPOSALS_BUDGET_OWN_COST_PERCENTAGE_OF_TOTAL'
            )}
          </div>
          {totalSum
            ? `${Math.round((ownContributionAmountSum / totalSum) * 100)} %`
            : ''}
        </div>
      </div>
      <SidebarSummary
        maxDotationAmount={contest.grantMaxAmount}
        minOwnContributionAmount={contest.ownContributionMinAmount}
        subsidyAmountSum={subsidyAmountSum}
        isIntigerSubsidyAmount={isInteger(subsidyAmountSum)}
        nodeRef={sidebarNodeRef}
        leftRequiredOwnContributionAmount={leftRequiredOwnContributionAmount}
      />
    </>
  )
}

const SidebarSummary = ({
  maxDotationAmount,
  minOwnContributionAmount,
  subsidyAmountSum,
  nodeRef,
  leftRequiredOwnContributionAmount,
  isIntigerSubsidyAmount,
}) => {
  const leftToDotate = maxDotationAmount
    ? maxDotationAmount - subsidyAmountSum
    : 0

  const classes = useBudgetCollectionTypeStyles()

  const summary = (
    <div className={classes.outer_summary_root}>
      <div className={classes.outer_summary_row}>
        <div>{translate('T_MODULE_PROPOSALS_MAX_DOTATION_AMOUNT')}</div>
        <div>
          {maxDotationAmount} {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>
      <div className={classes.outer_summary_row}>
        <div>{translate('T_MODULE_PROPOSALS_MIN_OWN_CONTRIBUTION_AMOUNT')}</div>
        <div>{minOwnContributionAmount} %</div>
      </div>
      <div
        className={clsx(
          classes.outer_summary_row,
          leftToDotate < 0 && classes.error
        )}
      >
        <div>{translate('T_MODULE_PROPOSALS_LEFT_TO_DOTATE')}</div>
        <div>
          {leftToDotate} {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>
      <div
        className={clsx(
          classes.outer_summary_row,
          leftRequiredOwnContributionAmount > 0 && classes.error
        )}
      >
        <div>
          {translate(
            'T_MODULE_PROPOSALS_LEFT_REQUIRED_OWN_CONTRIBUTION_AMOUNT'
          )}
        </div>
        <div>
          {leftRequiredOwnContributionAmount > 0
            ? leftRequiredOwnContributionAmount
            : 0}{' '}
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>

      <div className={clsx(classes.outer_summary_row)}>
        <div>
          Wnioskowane od FCP
          {isIntigerSubsidyAmount && (
            <small className={classes.error}>
              <br />
              Wnioskowana kwota musi stanowić liczbę całkowitą, a więc nie może
              zawierać groszy. Proszę skorygować wnioskowane kwoty w taki
              sposób, aby ich suma wynosiła kwotę wyrażoną pełnymi złotymi.
            </small>
          )}
        </div>
        <div>{subsidyAmountSum}</div>
      </div>
    </div>
  )

  return nodeRef ? createPortal(summary, nodeRef) : null
}

BudgetCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    grantMaxAmount: PropTypes.number,
    ownContributionMinAmount: PropTypes.number,
  }).isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  sidebarNodeRef: PropTypes.object,
}
