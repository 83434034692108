import React, { useMemo, useState } from 'react'
import { translate } from 'core/_helpers/translate'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import schema from '_schema/contestEdition'
import { standard } from 'core/components/table/_columns/standard'
import { operations } from 'core/components/table/_columns/operations'
import { Paper } from 'core/components/Paper'
import { CustomDialog } from 'core/components/Dialog'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { Form } from 'core/components/form'
import { Edit } from './Edit'
import { status } from 'core/components/table/_columns/status'

export const EditionCollection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('title'),
        sortable: false,
        width: '25%',
      },
      {
        ...standard('year'),
        sortable: false,
        width: '15%',
      },
      {
        ...standard('contestsCount'),
        sortable: false,
        width: '15%',
      },
      {
        ...status('isFinished', false, true, ['Zakończona', 'Trwająca']),
        sortable: false,
        filterable: false,
        width: '15%',
      },
      {
        ...standard('contractNumberNext'),
        sortable: false,
        width: '20%',
        description: {
          text: 'Licznik dla następnego numeru umowy w tej edycji',
        },
      },
      {
        ...operations(
          'title',
          true,
          true,
          false,
          resource => resource.contestsCount > 0,
          (resource, tableStateDispatch) => (
            <Edit resource={resource} tableStateDispatch={tableStateDispatch} />
          )
        ),
        width: '20%',
        editable: true,
        deletable: true,
      },
    ],
    []
  )

  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const [reload, setReload] = useState(false)

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleConfirmSuccess = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
    setReload(true)
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const handleSetReload = () => {
    setReload(state => !state)
  }

  const Dialog = () => (
    <>
      <Form
        url={schema.endpoint}
        method="POST"
        properties={schema.resource_write.properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fieldsFullWidth={true}
        width="100%"
        handleCancel={handleConfirmToggle}
        buttonContainerRef={buttonRef}
        handleSuccess={handleConfirmSuccess}
      />
    </>
  )

  return (
    <>
      <CollectionHeader hideButton buttonTitle="" buttonPath="">
        {translate('T_MANAGE_EDITIONS')}
      </CollectionHeader>
      <Paper withPadding={false}>
        <ButtonPlus text="Dodaj edycję" onClick={handleConfirmToggle} />
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema.endpoint}.get`}
          endpoint={schema.endpoint}
          customResourceSchema={schema.resource}
          definitionSchema={schema.resource}
          columns={columns}
          autoWidth={false}
          filterable={false}
          sortable={false}
          numerable={false}
          resetable={false}
          paginable={false}
          reload={reload}
          setReload={handleSetReload}
        />
        <CustomDialog
          title="T_NEW_EDITION"
          component={Dialog}
          confirmedButton={false}
          onConfirm={() => false}
          open={state.isConfirmOpen}
          handleToogle={handleConfirmToggle}
          isFetching={state.isFetching}
          showActions={true}
          onButtonRef={onButtonRef}
        />
      </Paper>
    </>
  )
}
