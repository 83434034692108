import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Collection } from './Collection'
import { Preview } from './Preview'
import { Contract } from './Contract'
import { Training } from './Training'
import { roles } from '_helpers/roles'

const routes = () => {
  return {
    index: {
      title: 'T_MODULE_PROPOSALS_APPLICATION_STATUS_FINISHED',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/proposals/finished',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    view: {
      type: Route,
      render: props => <Preview {...props} />,
      exact: true,
      resourceAccess: [roles.APPLICANT],
      path: '/proposals/finished/:id',
    },
    contract: {
      type: Route,
      //      render: props => (
      //        <SchemableComponent path="definitions" component={Contract} {...props} />
      //      ),
      render: props => <span>wg45rsger</span>,
      exact: true,
      resourceAccess: [roles.APPLICANT],
      path: '/proposals/finished/:id/contract',
    },
    training: {
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Training}
          {...props}
        />
      ),
      exact: true,
      resourceAccess: [roles.APPLICANT],
      //path: '/proposals/finished/:id/:index', // ugly url
      path: '/proposals/finished/:id/training/:index', // ugly url
    },
  }
}

export default routes
