import React, { useEffect, useState } from 'react'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { SectionTitle } from 'core/components/SectionTitle'
import { CustomDialog } from 'core/components/Dialog'
import clsx from 'clsx'
import schemaSubstantiveRate from '_schema/proposalSubstantiveRate'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schemaUser from 'core/_schema/user'
import schemaGuideline, {
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE,
} from '_schema/contestProposalRateGuidelines'
import { useStyleTable } from 'components/styledTable'
import schema from '_schema/proposal'
import { notification } from 'core/_helpers/notification'

const RateItem = ({ expert, rate }) => {
  const classes = useStyleTable()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(prevState => !prevState)
  }

  return (
    <>
      <TableCell
        className={clsx(
          classes.tableCell,
          classes.tableCellClick,
          classes.tableCellFontGreater
        )}
        align={'center'}
        onClick={handleClick}
      >
        <Tooltip title={`${expert.firstName} ${expert.lastName}`}>
          <span>{rate?.points}</span>
        </Tooltip>
      </TableCell>
      <CustomDialog
        handleToogle={handleClick}
        open={open}
        title={'Uzasadnienie'}
        showActions={false}
        component={() => <>{rate?.description}</>}
      />
    </>
  )
}

const Row = ({ el, experts, rates, index }) => {
  const classes = useStyleTable()

  let count = 0

  rates.forEach(item => {
    count += item?.points
  })

  return (
    <>
      <TableRow>
        <TableCell colSpan={experts.length + 1} className={classes.tableCell}>
          <SectionTitle
            label={`${translate('Kryterium')} ${index} - ${el.title} (${
              el.pointsMin
            }-${el.pointsMax} pkt)`}
            divider={false}
            marginTopSm={true}
          />
          <Typography variant={'body2'} color={'primary'}>
            {el.description}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {experts.map((el, index) => (
          <RateItem
            key={index}
            expert={el}
            rate={rates.find(r => r.expert === el['@id'])}
          />
        ))}
        <TableCell key={'count'} className={classes.cellTSum} align={'center'}>
          {count}
        </TableCell>
      </TableRow>
    </>
  )
}

export const RateSubstantive = ({ resource, event, updateResource }) => {
  const classes = useStyleTable()
  const [state, setState] = useState([])

  useEffect(() => {
    const iri = `${schemaSubstantiveRate.endpoint}?event.uuid=${event}&pagination=false`
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setState(resp['hydra:member'])
    })

    return () => controller.abort()
  }, [event])

  const [experts, setExperts] = useState([])

  useEffect(() => {
    const iri = `${schemaUser('expert').endpoint}?proposalUuid=${resource.uuid}`
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setExperts(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [resource.uuid])

  const [guideline, setGuideline] = useState([])

  useEffect(() => {
    const iri = `${schemaGuideline(null).endpoint}?contest.uuid=${
      resource.contest.uuid
    }&type=${CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE}&pagination=false`
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setGuideline(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [resource.contest.uuid])

  const [count, setCount] = useState(0)
  const [sum, setSum] = useState(0)
  useEffect(() => {
    if (guideline.length > 0 && experts.length > 0) {
      guideline.forEach(el => {
        const c = el.pointsMax * experts.length
        setCount(prevState => prevState + c)
      })
    }
  }, [guideline, experts])

  useEffect(() => {
    if (state.length > 0) {
      state.forEach(el => {
        setSum(prevState => prevState + el.points)
      })
    }
  }, [state])

  console.log(resource)
  console.log(event)
  // /proposals/setProposalVisibleForExperts/{id}

  const [visibleForExperts, setVisibleForExperts] = useState(
    resource.visibleForExperts
  )

  const handleClickShowExperts = () => {
    const iri = `${schema.endpoint}/setProposalVisibleForExperts/${resource.uuid}`
    fetchDataHandleAuthError(
      iri,
      'PUT',
      {
        body: JSON.stringify({}),
      },
      () => {
        notification(
          'success',
          translate('Wniosek został udostępniony dla ekspertów')
        )
        setVisibleForExperts(true)
        updateResource({
          ...resource,
          visibleForExperts: true,
        })
      }
    )
  }

  return (
    <>
      {!visibleForExperts && (
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleClickShowExperts}
          style={{ width: 'fit-content', marginBottom: 20 }}
        >
          Udostępnij wniosek dla ekspertów
        </Button>
      )}
      <Table
        size={'small'}
        aria-label={'Oceny merytoryczne'}
        className={classes.table}
        style={{ opacity: !visibleForExperts ? 0.5 : 1 }}
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {experts.map((el, index) => (
              <TableCell key={index} className={classes.tableHeadCell}>
                {el.firstName} {el.lastName}
              </TableCell>
            ))}
            <TableCell key="Razem" className={classes.tableTitle} align="right">
              {translate('Razem')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {guideline.map((el, index) => (
            <Row
              key={index}
              el={el}
              index={index + 1}
              rates={state.filter(st => st.rateGuideline === el['@id'])}
              experts={experts}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              align={'right'}
              colSpan={experts.length}
              className={clsx(classes.tableCell, classes.footerCell)}
            >
              <Typography variant={'subtitle1'}>
                {translate('Suma punktów')}:
              </Typography>
            </TableCell>
            <TableCell className={clsx(classes.cellTSum, classes.footerCell)}>
              {sum}/{count}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}
