import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { InsertDriveFileOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { formatBytes } from '_helpers/formatBytes'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 12,
    paddingBottom: 8,
    borderBottom: '1px solid #ccc',
  },
  description: {
    fontSize: 14,
    marginBottom: 12,
  },
  files: {
    marginTop: 24,
    marginLeft: 24,
  },
  file: {
    display: 'flex',
    marginBottom: 24,
  },
  file_details: {
    display: 'flex',
    alignItems: 'center',
  },
  file_info: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
  },
  file_title: {
    marginLeft: 100,
  },
  file_link: {
    color: theme.palette.secondary.main,
    marginBottom: 6,
  },
}))

export const File = ({ file, title = '', fileName }) => {
  const classes = useStyles()
  return (
    <div className={classes.file}>
      <div className={classes.file_details}>
        <InsertDriveFileOutlined fontSize="large" />
        <div className={classes.file_info}>
          <a
            href={`${process.env.REACT_APP_API_ENTRYPOINT}${file.url}`}
            target="_blank"
            className={classes.file_link}
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
          <span>{formatBytes(file.fileSize)}</span>
        </div>
      </div>
      {title && <div className={classes.file_title}>{title}</div>}
    </div>
  )
}

export const TextWithFiles = ({ title, description, files }) => {
  const classes = useStyles()

  const cleanDescription = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: true },
  })

  return (
    <div>
      {title && <div className={classes.title}>{title}</div>}
      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
          className={classes.description}
        ></div>
      )}
      {!!files.length && (
        <div className={classes.files}>
          {files.map(item => (
            <File
              file={item.file}
              title={item.title}
              fileName={item.fileName}
              key={item['@id']}
            />
          ))}
        </div>
      )}
    </div>
  )
}

TextWithFiles.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      file: PropTypes.shape({
        '@id': PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        fileSize: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
}
