import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { FormControl, FormHelperText } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { styles as translationTabStyles } from 'core/components/form/fields/translation/TranslationTab.styles'
import { CustomLabel } from './fields.style'

// TinyMCE so the global var exists
import 'tinymce/tinymce'
// DOM model
//import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/skins/ui/oxide/content.css'

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/bbcode'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/paste'

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis'

// eslint-disable-next-line no-undef
import 'tinymcelang/pl'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  label: {
    position: 'relative',
    marginBottom: 15,
    transform: 'none',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const TinymceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  formWidth = 300,
  translationLang = null,
  insertInfo = false,
}) => {
  const [id] = useState(randomHash())
  const theme = useTheme()

  const handleChange = content => {
    setValue(name, content)
    validateField(content)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <FormControl
      error={renderError && !!error}
      required={validators && validators.includes('required')}
      disabled={disabled}
      className={classes.root}
    >
      <CustomLabel shrink={false} error={false} htmlFor={id}>
        {translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')}
      </CustomLabel>
      <Editor
        style={{ width: '100%' }}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        disabled={disabled}
        id={id}
        init={{
          deprecation_warnings: false,
          menubar: 'file edit view insert',
          force_br_newlines: true,
          force_p_newlines: false,
          forced_root_block: '',
          entity_encoding: 'raw',
          language: process.env.REACT_APP_LOCALE,
          paste_as_text: true,
          image_advtab: true,
          plugins: [
            'autolink lists link charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime paste table',
          ],
          contextmenu: `link | copy paste ${insertInfo && '| insertcustom'}`,
          toolbar:
            'insertfile undo redo | styleselect | ' +
            'bold italic | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | caption link unlink charmap | insertcustom',
          extended_valid_elements:
            'span[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|' +
            'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
            'onkeydown|onkeyup],script[charset|defer|language|src|type]',
          style_formats: [
            {
              title: 'Inline',
              items: [
                { title: 'Bold', icon: 'bold', inline: 'strong' },
                { title: 'Italic', icon: 'italic', inline: 'em' },
                {
                  title: 'Underline',
                  icon: 'underline',
                  inline: 'span',
                  styles: { 'text-decoration': 'underline' },
                },
                {
                  title: 'Strikethrough',
                  icon: 'strikethrough',
                  inline: 'span',
                  styles: { 'text-decoration': 'line-through' },
                },
                { title: 'Superscript', icon: 'superscript', inline: 'sup' },
                { title: 'Subscript', icon: 'subscript', inline: 'sub' },
                { title: 'Code', icon: 'code', inline: 'code' },
              ],
            },
            {
              title: 'Blocks',
              items: [
                { title: 'Header', block: 'h3' },
                { title: 'Paragraph', block: 'p' },
                { title: 'Blockquote', block: 'blockquote' },
                { title: 'Div', block: 'div' },
                { title: 'Pre', block: 'pre' },
              ],
            },
          ],
          paste_text_sticky: true,
          paste_text_sticky_default: true,
          browser_spellcheck: true,
          width:
            formWidth -
            (translationLang &&
              translationTabStyles.root?.paddingLeft +
                translationTabStyles.root?.paddingRight),
          height: 300,
          skin: theme.palette.type === 'dark' ? 'oxide-dark' : '',
          content_css: theme.palette.type === 'dark' ? 'oxide-dark' : '',
          setup: editor => {
            editor.ui.registry.addMenuItem('insertcustom', {
              text: 'Wstaw zmienną',
              icon: 'plus',
              getSubmenuItems: () => {
                const items = [
                  {
                    type: 'menuitem',
                    text: 'NIP, REGON etc.',
                    onAction: () => {
                      editor.insertContent('&lt;ID&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Rodzaj numeru ident.',
                    onAction: () => {
                      editor.insertContent('&lt;TAXIDTYPE&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Pełna nazwa organizacji',
                    onAction: () => {
                      editor.insertContent('&lt;ORGNAME&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Typ organizacji',
                    onAction: () => {
                      editor.insertContent('&lt;ORGTYPE&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'E-mail',
                    onAction: () => {
                      editor.insertContent('&lt;ORGMAIL&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Adres organizacji',
                    onAction: () => {
                      editor.insertContent('&lt;ORGADDRESS&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Miasto organizacji',
                    onAction: () => {
                      editor.insertContent('&lt;ORGCITY&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Subkonto organizacji',
                    onAction: () => {
                      editor.insertContent('&lt;ORGSUBACCOUNT&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Osoby reprezentujące',
                    onAction: () => {
                      editor.insertContent('&lt;ORGREPRESENTATIVES&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Numer umowy',
                    onAction: () => {
                      editor.insertContent('&lt;CONTRACTNUMBER&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Nazwa projektu',
                    onAction: () => {
                      editor.insertContent('&lt;PROPOSALNAME&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Nazwa edycji',
                    onAction: () => {
                      editor.insertContent('&lt;PROPOSALEDITION&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Nazwa konkursu',
                    onAction: () => {
                      editor.insertContent('&lt;PROPOSALTITLE&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Kwota dotacji',
                    onAction: () => {
                      editor.insertContent('&lt;GRANDAMOUNT&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Osoba do kontaktu w sprawie projektu',
                    onAction: () => {
                      editor.insertContent('&lt;COORDINATOR&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Data realizacji projektu - Od',
                    onAction: () => {
                      editor.insertContent('&lt;REALIZATIONDATEFROM&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Data realizacji projektu - Do',
                    onAction: () => {
                      editor.insertContent('&lt;REALIZATIONDATETO&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Data raportu końcowego - Od',
                    onAction: () => {
                      editor.insertContent('&lt;ENDINGREPORTDATEFROM&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Data raportu końcowego - Do',
                    onAction: () => {
                      editor.insertContent('&lt;ENDINGREPORTDATETO&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Daty raportów kwartalnych',
                    onAction: () => {
                      editor.insertContent('&lt;QUARTERREPORTSDATES&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Data podpisania umowy',
                    onAction: () => {
                      editor.insertContent('&lt;CONTRACTSIGNINGDATE&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Tabela transz wypłat dotacji',
                    onAction: () => {
                      editor.insertContent('&lt;PROPOSALTRANCHES&gt;')
                    },
                  },
                  {
                    type: 'menuitem',
                    text: 'Znak podziału strony',
                    onAction: () => {
                      editor.insertContent('&lt;NEXTPAGE&gt;')
                    },
                  },
                ]
                return items
              },
            })
          },
        }}
        textareaName={name}
        value={value}
        onEditorChange={handleChange}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </FormControl>
  )
}

TinymceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translationLang: PropTypes.string,
  insertInfo: PropTypes.bool,
}
