import React from 'react'
import { Grid } from '@material-ui/core'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { Sidebar } from './components'
import { useTrainingState } from './_helpers/useTrainingState'
import { useTrainingFetch } from './_helpers/useTrainingFetch'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import schema from '_schema/proposal'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from 'pages/applicant/BlankForm'
import { TrainingForm } from './TrainingForm'
import { ReadFormGenerator } from '../../../Foundation/Projects/components/ReadFormGenerator'

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: 60,
    paddingLeft: 85,
    paddingRight: 85,
    fontWeight: 'bold',
    fontSize: 26,
    lineHeight: '30px',
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
  form: {
    width: 'auto',
    marginTop: 60,
    marginLeft: 82,
    marginRight: 82,
  },
  form_buttons: {
    justifyContent: 'center',
  },
}))

export const Training = ({ schema: definitions, match }) => {
  const iri = `${schema.endpoint}/${match.params.id}`

  const index = match.params.index.split('-').pop() - 1 // // ugly url

  const [proposalState, setProposalState] = useTrainingState()

  const { proposal, contest, isFetching, fetchError } = proposalState

  useTrainingFetch(
    iri,
    setProposalState.proposal,
    setProposalState.contest,
    setProposalState.form,
    setProposalState.fetchError,
    setProposalState.subresourceFetched,
    index
  )

  const classes = useStyles()

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ||
    proposal.applicationStatus !== proposalApplicationStatuses.APPLIED ? (
    <NotFound />
  ) : (
    <Grid container>
      <Grid item xs={8}>
        {proposal?.proposalTrainings?.[index]?.inquiryDate ? (
          <>
            <div className={classes.title}>
              Formularz szkolenia zamkniętego - {contest.title}
            </div>
            <div className={classes.form}>
              <ReadFormGenerator
                values={
                  proposal?.proposalTrainings?.[index]
                    ?.proposalTrainingFormValues
                }
                formIRI={
                  proposal?.proposalTrainings?.[index]?.contestTraining
                    .inquiryForm
                }
                contest={contest}
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.title}>
              Formularz szkolenia zamkniętego - {contest.title}
            </div>

            <BlankForm
              url={`/proposal_trainings/${proposal?.proposalTrainings?.[index]?.uuid}`}
              method="PUT"
              resource={{
                proposalTrainingFormValues:
                  proposal?.proposalTrainings?.[index]
                    ?.proposalTrainingFormValues,
              }}
              definitionSchema={
                definitions[schema.resource_trainings.definition]
              }
              customResourceSchema={schema.resource_trainings}
              submitButtonTitle={'Prześlij'}
              submitButtonVariant="outlined"
              classes={{
                root: classes.form,
                buttons: classes.form_buttons,
              }}
              key={`/proposal_trainings/${proposal?.proposalTrainings?.[index]?.uuid}`}
            >
              {({ state, properties, resource, setValue, setError }) => (
                <>
                  <TrainingForm
                    contest={contest}
                    state={state}
                    properties={properties}
                    resource={resource}
                    setValue={setValue}
                    setError={setError}
                    proposalState={proposalState}
                  />
                </>
              )}
            </BlankForm>
          </>
        )}
      </Grid>
      <Grid item xs={4}>
        <Sidebar
          proposalUuid={match.params.id}
          contestUuid={contest.uuid}
          collectingEndDate={contest.collectingEndDate}
          applicationDate={proposal.applicationDate}
          formalRatingDateTo={contest.formalRatingDateTo}
          contractSigningDate={proposal.contractSigningDate}
          contractSignedFile={proposal.contractFile}
          contestTrainings={contest.trainings}
          acceptationStatus={proposal.acceptationStatus}
          withEditButton={false}
          proposalTrainings={proposal.proposalTrainings}
          proposalNumber={proposal?.proposalNumber}
        />
      </Grid>
    </Grid>
  )
}
