import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const useProposalFetch = (
  iri,
  setProposal,
  setContest,
  setFetchError,
  setSubresourceFetched
) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      iri,
      'GET',
      { signal },
      response => {
        setProposal(response)

        if (response.contest) {
          getContest(response.contest, signal, setContest, setFetchError)
        } else {
          setSubresourceFetched('contest')
        }
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setFetchError(true)
      }
    )

    return () => controller.abort()
  }, [iri, setProposal, setContest, setFetchError, setSubresourceFetched])

  return
}

const getContest = (iri, signal, setContest, setFetchError) => {
  fetchDataHandleAuthError(
    iri,
    'GET',
    { signal },
    response => {
      setContest(response)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
