import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { TabPanel } from 'components/TabPanel'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import {
  Sidebar,
  ContractSteps,
  FillStep,
  ConfirmStep,
  UploadStep,
} from './components'
import { useProposalState } from './_helpers/useProposalState'
import { useProposalFetch } from './_helpers/useProposalFetch'
import { useContractStyles } from './Contract.styles'
import { translate } from 'core/_helpers/translate'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import { contractSteps } from './_helpers/contractSteps'
import schema, { CONTRACT_STATUS_TO_CORRECT } from '_schema/proposal'

export const Contract = ({ schema: definitions, match }) => {
  const iri = `${schema.endpoint}/${match.params.id}`

  const [proposalState, setProposalState] = useProposalState()

  const { proposal, contest, isFetching, fetchError } = proposalState

  useProposalFetch(
    iri,
    setProposalState.proposal,
    setProposalState.contest,
    setProposalState.form,
    setProposalState.fetchError,
    setProposalState.subresourceFetched
  )

  const [activeStep, setActiveStep] = useState(null)

  useEffect(() => {
    if (!proposal) {
      return
    }

    setActiveStep(state =>
      !state
        ? proposal.isContractDataAccepted
          ? contractSteps.CONFIRM
          : contractSteps.FILL
        : state
    )
  }, [proposal])

  const handleStepChange = step => () => {
    setActiveStep(step)
  }

  const classes = useContractStyles()

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ||
    proposal.applicationStatus !== proposalApplicationStatuses.APPLIED ? (
    <NotFound />
  ) : (
    <Grid container>
      <Grid item xs={8}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_CONTRACT')}
        </div>
        <div className={classes.info}>
          <div className={classes.info_header}>
            {translate('T_MODULE_PROPOSALS_MANUAL_TITLE')}
          </div>
          <ul>
            <li>{translate('T_MODULE_PROPOSALS_MANUAL_LINE_1')}</li>
            <li>{translate('T_MODULE_PROPOSALS_MANUAL_LINE_2')}</li>
            <li>{translate('T_MODULE_PROPOSALS_MANUAL_LINE_3')}</li>
            <li>{translate('T_MODULE_PROPOSALS_MANUAL_LINE_4')}</li>
            <li>{translate('T_MODULE_PROPOSALS_MANUAL_LINE_5')}</li>
            <li>{translate('T_MODULE_PROPOSALS_MANUAL_LINE_6')}</li>
          </ul>
          <div className={classes.info_footer}>
            {translate('T_MODULE_PROPOSALS_MANUAL_ADDRESS_LINE_1')}
            <br />
            {translate('T_MODULE_PROPOSALS_MANUAL_ADDRESS_LINE_2')}
            <br />
            {translate('T_MODULE_PROPOSALS_MANUAL_ADDRESS_LINE_3')}
          </div>
          {proposal.contractStatus === CONTRACT_STATUS_TO_CORRECT &&
            proposal.contractReturnMessage && (
              <div className={classes.return_message}>
                {proposal.contractReturnMessage}
              </div>
            )}
        </div>
        <div>
          <ContractSteps
            isFillStepDisabled_XXXX={proposal.isContractDataAccepted}
            isFillStepDisabled={true}
            isConfirmStepDisabled={isConfirmStepDisabled(proposal)}
            isUploadStepDisabled_XXXX={!proposal.isContractDataAccepted}
            isUploadStepDisabled={true}
            activeStep={activeStep}
            handleStepChange={handleStepChange}
          />
          <div className={classes.steps_container}>
            <TabPanel
              key={contractSteps.CONFIRM}
              index={contractSteps.CONFIRM}
              value={activeStep}
            >
              <ConfirmStep
                resource={proposal}
                setResource={setProposalState.proposal}
                key={proposal.updatedAt}
              />
            </TabPanel>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Sidebar
          proposalUuid={match.params.id}
          contestUuid={contest.uuid}
          collectingEndDate={contest.collectingEndDate}
          applicationDate={proposal.applicationDate}
          formalRatingDateTo={contest.formalRatingDateTo}
          contractSigningDate={proposal.contractSigningDate}
          contractSignedFile={proposal.contractFile}
          contestTrainings={contest.trainings}
          acceptationStatus={proposal.acceptationStatus}
          withEditButton={false}
        />
      </Grid>
    </Grid>
  )
}

const isConfirmStepDisabled = proposal => {
  if (
    !proposal.contractSubAccount ||
    !proposal.contractOrganizationName ||
    !proposal.contractOrganizationType ||
    !proposal.contractPostOffice ||
    !proposal.contractPostCode ||
    !proposal.contractAddress ||
    !proposal.contractCity ||
    !proposal.contractCommune ||
    !proposal.contractDistrict ||
    !proposal.contractProvince
  ) {
    return true
  }

  return false
}
