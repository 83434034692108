import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { FormTemplate } from './FormTemplate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 15,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
}))

export const ProjectStep = ({
  mapping,
  title,
  values,
  contest,
  proposal,
  additionalResources,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_PROJECT_TITLE')}
        </div>
        {title}
      </div>
      <div>
        <FormTemplate
          mapping={mapping}
          values={values}
          contest={contest}
          proposal={proposal}
          additionalResources={additionalResources}
        />
      </div>
    </div>
  )
}

ProjectStep.propTypes = {
  mapping: PropTypes.array,
  title: PropTypes.string,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  contest: PropTypes.object,
  proposal: PropTypes.object,
  additionalResources: PropTypes.object.isRequired,
}
