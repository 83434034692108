import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const useStatusFetch = (getEndpoint, id, setResource, setFetchError) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    getStatus(getEndpoint, id, signal, setResource, setFetchError)

    return () => controller.abort()
  }, [getEndpoint, id, setResource, setFetchError])

  return
}

const getStatus = (getEndpoint, id, signal, setResource, setFetchError) => {
  fetchDataHandleAuthError(
    `${getEndpoint}/last?proposal.uuid=${id}`,
    'GET',
    { signal },
    response => {
      setResource(response['hydra:member'])
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
