import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import { SectionTitle } from 'core/components/SectionTitle'
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { useStyleTable } from 'components/styledTable'
import { downloadFile } from '_helpers/downloadFile'
import { translate } from 'core/_helpers/translate'
import schema, {
  STATUS_ACCEPTED,
  STATUS_REPORTED,
  STATUS_NOTREPORTED,
} from '_schema/proposalQuarterlyDeclaration'
import clsx from 'clsx'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles({
  btn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const SubTable = ({ items, subsidyAmount }) => {
  const tableClasses = useStyleTable()
  return (
    <>
      <Table className={tableClasses.table} size={'small'}>
        <TableHead className={tableClasses.tableHead}>
          <TableRow>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Data')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Numer i nazwa')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Wystawca dokumentu')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Netto')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Brutto')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell} colSpan={2}>
              {translate('Z dotacji')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, i) => (
            <TableRow key={i}>
              <TableCell className={tableClasses.tableCell}>
                {moment(item.documentDate).format(DATE_FORMAT)}
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {item.documentName} {item.documentNumber}
              </TableCell>
              <TableCell className={clsx(tableClasses.tableCell)}>
                {item.documentCreator}
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {Number(item.nettoAmount).toFixed(2)} zł
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {Number(item.bruttoAmount).toFixed(2)} zł
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {Number(item.subsidyAmount).toFixed(2)} zł
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {((item.subsidyAmount / subsidyAmount) * 100).toFixed(2)} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export const QuarterDeclaration = ({
  eventObject,
  setEvent,
  updateResource,
}) => {
  const tableClasses = useStyleTable()

  const { quarterlyDeclaration } = eventObject

  // console.log(quarterlyDeclaration)

  function totalNettoAmount(items) {
    return items
      .map(({ nettoAmount }) => nettoAmount)
      .reduce((sum, i) => sum + i, 0)
  }
  function totalNettoItemsAmount(items) {
    return items
      .map(({ budgetCharges }) =>
        budgetCharges
          .map(({ nettoAmount }) => nettoAmount)
          .reduce((sum, i) => sum + i, 0)
      )
      .reduce((sum, i) => sum + i, 0)
  }
  function totalBruttoItemsAmount(items) {
    return items
      .map(({ budgetCharges }) =>
        budgetCharges
          .map(({ bruttoAmount }) => bruttoAmount)
          .reduce((sum, i) => sum + i, 0)
      )
      .reduce((sum, i) => sum + i, 0)
  }

  function totalSubsidyAmount(items) {
    return items
      .map(({ subsidyAmount }) => subsidyAmount)
      .reduce((sum, i) => sum + i, 0)
  }

  function totalPercent(items) {
    const totalSubsidy = totalSubsidyAmount(items)
    const totalNetto = totalNettoAmount(items)
    return (totalNetto * 100) / totalSubsidy
  }

  const handleDownload = () => {
    downloadFile(
      schema.endpoint_download.replace(':id', quarterlyDeclaration[0].uuid),
      `deklaracja-kwartalna-${quarterlyDeclaration[0].uuid}.pdf`
    )
  }

  const [isFetching, setIsFetching] = useState(false)

  const handleAccept = () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_accept.replace(':id', quarterlyDeclaration[0].uuid),
      'PATCH',
      { body: JSON.stringify({}) },
      () => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

        setEvent({
          quarterlyDeclaration: [
            {
              ...quarterlyDeclaration[0],
              status: STATUS_ACCEPTED,
              acceptedAt: moment().format('YYYY-MM-DD'),
            },
          ],
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleCancel = () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_cancel.replace(':id', quarterlyDeclaration[0].uuid),
      'PUT',
      { body: JSON.stringify({}) },
      () => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

        setEvent({
          quarterlyDeclaration: [
            {
              ...quarterlyDeclaration[0],
              status: STATUS_NOTREPORTED,
              acceptedAt: moment().format('YYYY-MM-DD'),
            },
          ],
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleDelete = () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_delete.replace(':id', quarterlyDeclaration[0].uuid),
      'DELETE',
      { body: JSON.stringify({}) },
      () => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

        updateResource()
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const localClasses = useStyles()

  return (
    <>
      <SectionTitle
        label={'Deklaracja za okres:'}
        marginTopSm={true}
        text={getDateRange(quarterlyDeclaration[0].quarterId)}
      />
      <Grid container spacing={3} justify={'space-between'}>
        <Grid item xs={4} md={2}>
          <Typography color="primary" variant="subtitle1">
            {quarterlyDeclaration[0].status === STATUS_REPORTED &&
              moment(quarterlyDeclaration[0].reportedAt).format(DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item xs={8} md={6}>
          {quarterlyDeclaration[0].status !== STATUS_NOTREPORTED && (
            <ButtonDownload
              text={'Pobierz wygenerowany pdf'}
              onClick={handleDownload}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            color="primary"
            variant="subtitle1"
            style={{ textAlign: 'right' }}
          >
            {quarterlyDeclaration[0].status === STATUS_ACCEPTED &&
              `Zaakceptowana ${moment(
                quarterlyDeclaration[0].acceptedAt
              ).format(DATE_FORMAT)}`}
          </Typography>
        </Grid>
      </Grid>
      <SectionTitle label={'Deklaracja finansowa'} divider={true} />
      <Table className={tableClasses.table}>
        <TableHead className={tableClasses.tableHead}>
          <TableRow>
            <TableCell
              className={tableClasses.tableHeadCell}
              style={{ width: '50%' }}
              colSpan={3}
            >
              {translate('Kategoria budżetowa')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Dotowano')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Wydatki')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('% wykorzystania budżetu')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quarterlyDeclaration[0].quarterlyDeclarationItem.map(
            quarter =>
              quarter.budget && (
                <>
                  <TableRow key={quarter.uuid}>
                    <TableCell
                      className={clsx(tableClasses.cellTSum)}
                      colSpan={3}
                    >
                      <div className={tableClasses.cellDivider}>
                        <span>{quarter.title}</span>
                        <Divider />
                      </div>
                    </TableCell>
                    <TableCell className={clsx(tableClasses.cellTSum)}>
                      <div className={tableClasses.cellDivider}>
                        <span>
                          {Number(quarter.subsidyAmount).toFixed(2)} zł
                        </span>
                        <Divider />
                      </div>
                    </TableCell>
                    <TableCell className={clsx(tableClasses.cellTSum)}>
                      <div className={tableClasses.cellDivider}>
                        <span>{Number(quarter.nettoAmount).toFixed(2)} zł</span>
                        <Divider />
                      </div>
                    </TableCell>
                    <TableCell className={tableClasses.cellTSum}>
                      {Number(quarter.precent).toFixed(2)} %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={tableClasses.tableCell} colSpan={6}>
                      <SubTable
                        items={quarter.budgetCharges}
                        subsidyAmount={quarter.subsidyAmount}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              className={tableClasses.tableHeadCell}
              style={{ width: '30%', textAlign: 'right' }}
            ></TableCell>
            <TableCell className={tableClasses.tableHeadCell}>Netto</TableCell>
            <TableCell className={tableClasses.tableHeadCell}>Brutto</TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              Dotowano
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              Z dotacji
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              % wykorzystania budżetu
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={tableClasses.tableTitle}
              style={{ width: '30%', textAlign: 'right' }}
            >
              {translate('Suma')}:
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(
                totalNettoItemsAmount(
                  quarterlyDeclaration[0].quarterlyDeclarationItem
                )
              ).toFixed(2)}{' '}
              zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(
                totalBruttoItemsAmount(
                  quarterlyDeclaration[0].quarterlyDeclarationItem
                )
              ).toFixed(2)}{' '}
              zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(
                totalSubsidyAmount(
                  quarterlyDeclaration[0].quarterlyDeclarationItem
                )
              ).toFixed(2)}{' '}
              zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {totalNettoAmount(
                quarterlyDeclaration[0].quarterlyDeclarationItem
              ).toFixed(2)}{' '}
              zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(
                totalPercent(quarterlyDeclaration[0].quarterlyDeclarationItem)
              ).toFixed(2)}{' '}
              %
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <div className={localClasses.btn}>
        {(quarterlyDeclaration[0].status === STATUS_REPORTED ||
          quarterlyDeclaration[0].status === STATUS_NOTREPORTED) && (
          <>
            <Button
              variant="contained"
              color="secondary"
              disabled={isFetching}
              onClick={handleDelete}
            >
              Usuń deklarację
            </Button>
            &nbsp;&nbsp;
          </>
        )}
        {(quarterlyDeclaration[0].status === STATUS_REPORTED ||
          quarterlyDeclaration[0].status === STATUS_ACCEPTED) && (
          <>
            <Button
              variant="contained"
              color="secondary"
              disabled={isFetching}
              onClick={handleCancel}
            >
              Wycofaj deklarację
            </Button>
          </>
        )}
        &nbsp;&nbsp;
        {quarterlyDeclaration[0].status === STATUS_REPORTED && (
          <>
            <Button
              variant="contained"
              color="secondary"
              disabled={isFetching}
              onClick={handleAccept}
            >
              Potwierdź złożenie deklaracji
            </Button>
          </>
        )}
      </div>
    </>
  )
}

const getDateRange = quarterId => {
  const quarterYear = quarterId.split('-')[0]
  const quarterNum = parseInt(quarterId.split('-')[1])

  switch (quarterNum) {
    case 1:
      return `01.01.${quarterYear} - 31.03.${quarterYear}`
    case 2:
      return `01.04.${quarterYear} - 30.06.${quarterYear}`
    case 3:
      return `01.07.${quarterYear} - 30.09.${quarterYear}`
    case 4:
    default:
      return `01.10.${quarterYear} - 31.12.${quarterYear}`
  }
}
