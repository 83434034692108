import { useState, useMemo, useEffect } from 'react'

export const useReportState = (type = 'finalReport') => {
  const [state, setState] = useState({
    isFetching: true,
    proposal: null,
    contest: null,
    fetchError: false,
    contestFetched: false,
    [`${type}FormFetched`]: false,
  })

  const setters = useMemo(
    () => ({
      isFetching: isFetching =>
        setState(state => ({
          ...state,
          isFetching,
        })),
      proposal: proposal =>
        setState(state => ({
          ...state,
          proposal,
        })),
      contest: contest =>
        setState(state => ({
          ...state,
          contestFetched: true,
          contest,
        })),
      form: form => {
        setState(state => ({
          ...state,
          [`${type}FormFetched`]: true,
          contest: {
            ...state.contest,
            [`${type}Form`]: form,
          },
        }))
      },
      fetchError: fetchError =>
        setState(state => ({
          ...state,
          isFetching: false,
          fetchError,
        })),
      formFetched: () => {
        setState(state => ({
          ...state,
          [`${type}FormFetched`]: true,
        }))
      },
    }),
    [type, setState]
  )

  useEffect(() => {
    if (!state.contestFetched || !state[`${type}FormFetched`]) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))
  }, [type, state.contestFetched, setState, state])

  return [state, setters]
}
