import React from 'react'
import proposalSchema from '_schema/proposal'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { BudgetTabs } from 'pages/applicant/FinishedProposals/Budget/BudgetTabs'
import routes from '../routes'
import { useBudgetFetch } from './_helpers/useBudgetFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import { FormsWrapper } from './StatementBudget/FormsWrapper'
import { makeStyles } from '@material-ui/styles'
import { useProject } from '_helpers/useProject'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 30,
  },
  form: {
    width: 'fit-content',
  },
  form_buttons: {
    marginTop: -75,
    marginBottom: 25,
  },
}))
export const StatementBudget = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()
  const proposalUuid = match.params.id

  useProject(proposalUuid)

  const [budgetsState, setBudgetsState] = useResourceState()

  const {
    resource: budgets,
    isFetching: budgetsIsFetching,
    fetchError: budgetsFetchError,
  } = budgetsState

  useBudgetFetch(
    proposalSchema.endpoint_budgets,
    proposalUuid,
    setBudgetsState.resource,
    setBudgetsState.fetchError
  )

  return budgetsIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : budgetsFetchError ? (
    <NotFound />
  ) : (
    <>
      <BudgetTabs
        value={routes().statement.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        {budgets?.map((item, i) => (
          <div key={`${item}-${i}`}>
            {!item.title &&
            (!item.subsidyAmountCurrent || item.subsidyAmountCurrent === 0) ? (
              <></>
            ) : (
              <>
                <h3 className={localClasses.header}>
                  {' '}
                  {item.titleCurrent} - budżet:{' '}
                  {Number(item.subsidyAmountCurrent).toFixed(2)} zł
                </h3>
                <FormsWrapper
                  schema={definitions}
                  budgetlUuid={item.uuid}
                  localClasses={localClasses}
                />
              </>
            )}
          </div>
        ))}
      </Paper>
    </>
  )
}
