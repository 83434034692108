import { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import buildUrl from 'build-url'

const initialState = {
  items: [],
  totalItems: null,
  init: true,
}

export const useProposalRelationCollectionFetch = (endpoint, proposalUuid) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const url = buildUrl(endpoint, {
      queryParams: {
        'proposal.uuid': proposalUuid,
        pagination: false,
      },
    })

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        setState({
          items: response['hydra:member'],
          totalItems: response['hydra:totalItems'],
          init: false,
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, proposalUuid])

  return [state, setState]
}
