import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { applyProperties } from 'core/_helpers/applyProperties'
import { notification } from 'core/_helpers/notification'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import { SubmitButton } from 'components/applicant/form/buttons'
import { initState } from 'components/applicant/form/_helpers/initState'
import { constants, reducer } from 'components/applicant/form/_state'
import { useStyles } from 'components/applicant/form'
import { translate } from 'core/_helpers/translate'

export const ProposalRelationForm = ({
  url,
  method,
  definitionSchema,
  customResourceSchema,
  resource = null,
  handleSuccess,
  children,
  classes = {},
}) => {
  const properties = applyProperties(
    customResourceSchema.properties,
    definitionSchema.properties,
    'missing'
  )

  const [state, dispatch] = useReducer(
    reducer,
    {
      url,
      method,
      resource,
      properties,
      onlyPassedProperties: true,
    },
    initState
  )

  const setValue = useCallback((name, value, setRenderError = true) => {
    dispatch({
      type: constants.SET_VALUE,
      payload: { name, value, setRenderError },
    })
  }, [])

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  const handleSubmit = isApply => e => {
    e.preventDefault()

    dispatch({ type: constants.SUBMIT })

    if (state.isInvalid) {
      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      url,
      method,
      {
        body: JSON.stringify({
          ...state.values,
          ...(isApply ? { isApply: true } : {}),
        }),
      },
      response => {
        if (properties?.stat === undefined && response?.stat !== undefined) {
          delete response.stat
        }

        dispatch({
          type: constants.SUCCESS,
          payload: {
            method: 'PUT',
            resource: response,
          },
        })

        handleSuccess()
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const defaultClasses = useStyles()

  return (
    <form className={clsx(defaultClasses.root, classes.root)}>
      {children({
        state,
        properties,
        resource,
        setValue,
        setError,
      })}
      <div className={clsx(defaultClasses.buttons, classes.buttons)}>
        {state.isProcessing ? (
          <Loader classes={{ root: classes.loader }} />
        ) : (
          <>
            <SubmitButton
              title={translate('T_GENERAL_SAVE')}
              variant="outlined"
              size="large"
              handleSubmit={handleSubmit(false)}
              disabled={
                state.isProcessing || (state.isSubmitted && state.isInvalid)
              }
              classes={{ submit: classes.submit }}
            />
            <SubmitButton
              title={translate('T_MODULE_PROPOSALS_SAVE_AND_APPLY')}
              variant="contained"
              size="large"
              handleSubmit={handleSubmit(true)}
              disabled={
                state.isProcessing || (state.isSubmitted && state.isInvalid)
              }
              classes={{ submit: classes.submit }}
            />
          </>
        )}
      </div>
    </form>
  )
}

ProposalRelationForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  resource: PropTypes.object,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  handleSuccess: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  classes: PropTypes.object,
}
