import React, { useCallback, useEffect, useState } from 'react'
import { useBlockCollectionStyles } from 'core/components/block'
import clsx from 'clsx'
import {
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import arrayMove from 'array-move'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { constants } from 'core/components/table/_state'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'core/components/table'
import { AddQuestion } from './AddQuestion'
import schema from '_schema/faq'
import { Delete } from 'core/components/resource/Delete'
import { Form } from 'core/components/form'
import photoSchema from 'core/_schema/photo'
export const QuestionsCollection = ({ dispatch, item }) => {
  const defaultClasses = useBlockCollectionStyles()

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      const elements = item.faqItem

      const newOrders = arrayMove(elements, oldIndex, newIndex)

      const newList = newOrders.map((el, index) => {
        fetchDataHandleAuthError(el['@id'], 'PUT', {
          body: JSON.stringify({
            ord: index + 1,
          }),
        })
        return {
          ...el,
          ord: index + 1,
        }
      })

      dispatch({
        type: constants.UPDATE_ITEM,
        payload: {
          '@id': item['@id'],
          value: {
            ...item,
            faqItem: newList,
          },
        },
      })
    },
    [dispatch, item]
  )

  const handleDelete = () => {
    dispatch({
      type: constants.RELOAD,
    })
  }

  const [showSave, setShowSave] = useState([])
  useEffect(() => {
    setShowSave(item.faqItem.map(() => false))
  }, [item.faqItem])

  const handleChange = useCallback(i => {
    setShowSave(prevState => prevState.map((el, index) => index === i))
  }, [])

  return (
    <>
      <div className={clsx(defaultClasses.header)}>
        <div className={defaultClasses.sectionTitle}>
          <Typography color="primary" variant="h2">
            {translate('Pytania')}
          </Typography>
          <Divider />
        </div>
      </div>
      <TableContainer className={clsx(defaultClasses.collectionContainer)}>
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow)}
            useDragHandle={true}
          >
            {item.faqItem.length > 0 ? (
              item.faqItem.map((it, i) => {
                console.log(it)
                const properties = schema.resource_question.properties

                const handleSuccess = () => {
                  dispatch({
                    type: constants.RELOAD,
                  })
                }
                const beforeSubmit = data => {
                  const obj = data
                  obj.faqCategory = it.faqCategory
                  return obj
                }
                return (
                  <ManuallyOrderableTableRow index={i} key={i}>
                    <TableCell
                      key="element"
                      className={clsx(defaultClasses.cell)}
                      style={{ width: '100%' }}
                    >
                      <div className={defaultClasses.expansionPanel}>
                        <div className={defaultClasses.expansionPanelSummary}>
                          <ManualOrderDragHandle />
                          <Typography
                            className={clsx(defaultClasses.blockTitle)}
                            color={'primary'}
                            variant={'h2'}
                          >
                            <span>{translate(it.question)}</span>
                          </Typography>
                          <div className={clsx(defaultClasses.blockIcon)}>
                            <Delete
                              resource={it}
                              accessor={`${process.env.REACT_APP_LOCALE}.question`}
                              disabled={false}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                        </div>
                        <div className={defaultClasses.itemContainer}>
                          <Form
                            readOnly={false}
                            url={it['@id']}
                            method={'PUT'}
                            properties={properties}
                            resource={it}
                            fetchCompareResource={false}
                            // handleSubmit={(state, disptach) =>
                            //   handleSubmit(state, disptach, item)
                            // }
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showSubmitButton={showSave[i]}
                            isChange={() => handleChange(i)}
                            showCancelButton={false}
                            fieldsFullWidth={true}
                            width={'100%'}
                            key={`${
                              it[process.env.REACT_APP_RESOURCE_ID]
                            }-${i}`}
                            customBeforeSubmit={beforeSubmit}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(defaultClasses.emptyResults)}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <AddQuestion
        endpoint={schema.endpoint_item}
        parent={item['@id']}
        dispatch={dispatch}
      />
    </>
  )
}
