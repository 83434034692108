import { makeStyles } from '@material-ui/styles'

export const useFillStepStyles = makeStyles(theme => ({
  form: {
    color: theme.palette.primary.main,
    marginBottom: 40,
  },
  form_buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    '&> *': {
      margin: 0,
    },
  },
  row: {
    marginBottom: 10,
  },
  row_two_col: {
    display: 'flex',
    alignItems: 'flex-start',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  input: {
    width: 350,
  },
  input_post_code: {
    width: 80,
  },
  input_tax_number: {
    width: 250,
  },
  select: {
    width: 220,
  },
  select_tax_id: {
    width: 100,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 30,
  },
  label: {
    fontSize: 14,
    marginBottom: 8,
  },
  edit_mode_button_container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  edit_mode_button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.secondary.main,
    '&> *:first-child': {
      marginRight: 6,
    },
  },
  not_editable_row: {
    marginBottom: 18,
    fontSize: 16,
  },
  not_editable_row_post: {
    display: 'flex',
    marginBottom: 18,
    fontSize: 16,
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  not_editable_label: {
    fontWeight: 'bold',
    marginBottom: 6,
  },
}))
