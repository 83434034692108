import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { StringType } from 'components/applicant/form/fields/StringType'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  info: {
    marginBottom: 8,
  },
  label: {
    marginTop: 6,
  },
  input: {
    width: 100,
  },
  help: {
    marginLeft: 25,
    color: theme.palette.disabledDark,
  },
  flex: {
    display: 'flex',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
}))

export const ParticipantsCountBlock = ({
  data,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled,
}) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.PARTICIPANTS_COUNT, data),
    [data]
  )

  const validators = useMemo(
    () => ({
      inputsFrom: [
        ...(mapping.inputs.required ? ['required'] : []),
        ...(mapping.inputs.from
          ? [`minValue(${mapping.inputs.from})`]
          : [`minValue(1)`]),
        ...(mapping.inputs.to ? [`maxValue(${mapping.inputs.to})`] : []),
      ],
      inputsTo: [
        ...(mapping.inputs.required ? ['required'] : []),
        ...(values !== undefined && values.from !== undefined && values.from
          ? [`minValue(${values.from})`]
          : [`minValue(1)`]),
        ...(mapping.inputs.to ? [`maxValue(${mapping.inputs.to})`] : []),
      ],
    }),
    [mapping, values]
  )

  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT')}
        </div>
        <div className={classes.info}>
          {translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_MIN')}:{' '}
          {mapping.inputs.from ||
            translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_NO_LIMIT')}
          , {translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_MAX')}:{' '}
          {mapping.inputs.to ||
            translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_NO_LIMIT')}
        </div>
        <div className={classes.flex}>
          <div className={classes.flex}>
            <div className={classes.label}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_FROM')}
            </div>
            <StringType
              type="number"
              name="from"
              value={values?.from}
              error={errors?.from}
              renderError={globalRenderError && renderError?.from}
              disabled={disabled}
              validators={validators.inputsFrom}
              setValue={setValue}
              setError={setError}
              classes={{
                root: classes.input,
              }}
              min={mapping.inputs.from ? mapping.inputs.from : 1}
              max={mapping.inputs.to ? mapping.inputs.to : null}
            />
          </div>
          <div className={classes.flex}>
            <div className={classes.label}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_TO')}
            </div>
            <StringType
              type="number"
              name="to"
              value={values?.to}
              error={errors?.to}
              renderError={globalRenderError && renderError?.to}
              disabled={disabled}
              validators={validators.inputsTo}
              setValue={setValue}
              setError={setError}
              classes={{
                root: classes.input,
              }}
              min={mapping.inputs.from ? mapping.inputs.from : 1}
              max={mapping.inputs.to ? mapping.inputs.to : null}
            />
          </div>
        </div>
      </div>
      <div className={classes.help}>{mapping.inputs.help}</div>
    </div>
  )
}

ParticipantsCountBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
