import { makeStyles } from '@material-ui/styles'

export const useConfirmStepStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  row: {
    marginBottom: 10,
    fontSize: 16,
  },
  row_two_col: {
    display: 'flex',
    alignItems: 'flex-start',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 30,
    marginTop: 40,
  },
  label: {
    fontSize: 14,
    marginBottom: 8,
    fontWeight: 'bold',
  },
  representative_row: {
    display: 'flex',
    alignItems: 'center',
    '&> *': {
      marginRight: 20,
    },
  },
  not_editable_row: {
    marginBottom: 18,
    fontSize: 16,
  },
  not_editable_row_post: {
    display: 'flex',
    marginBottom: 18,
    fontSize: 16,
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  not_editable_label: {
    fontWeight: 'bold',
    marginBottom: 6,
  },
  button_container: {
    marginTop: 30,
    marginBottom: 40,
  },
}))
