import React, { useState } from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { ProjectScheduleTabs } from 'pages/applicant/FinishedProposals/ProjectSchedule/ProjectScheduleTabs'
import routes from '../routes'
import { useProposalFetch } from './_helpers/useProposalFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import proposalSchema from '_schema/proposal'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from 'core/_helpers/useResourceState'
import { Form } from './LeadTime/components'
import { FormAnswer } from './LeadTime/components'
import moment from 'moment'
import { useProject } from '_helpers/useProject'
import { translate } from 'core/_helpers/translate'
import { CustomDialog } from 'core/components/Dialog'
import { useHistory } from 'react-router-dom'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  list_col: {
    backgroundColor: 'white',
    marginLeft: 45,
    marginRight: 100,
    marginTop: 30,
    padding: 30,
  },
  form_col: {
    paddingTop: 60,
    paddingBottom: 60,
    paddingRight: 70,
    paddingLeft: 70,
    backgroundColor: 'white',
  },
  header: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: 0,
  },
  changesItem: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginTop: 15,
    marginBottom: 0,
  },
}))

export const LeadTime = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()
  const history = useHistory()

  const proposalUuid = match.params.id

  const projekt = useProject(proposalUuid)

  const [open, setOpen] = useState(false)

  const [proposalState, setProposalState] = useResourceState()

  const {
    resource: proposalResource,
    isFetching: proposalResourceIsFetching,
    fetchError: proposalResourceFetchError,
  } = proposalState

  const iri = `${proposalSchema.endpoint}/${proposalUuid}`

  useProposalFetch(
    //proposalSchema.endpoint,
    proposalSchema.endpoint_realization_date_requests,
    proposalUuid,
    setProposalState.resource,
    setProposalState.fetchError
  )

  const handleSuccess = response => {
    setProposalState.resource([response, ...proposalResource])
    setOpen(true)
  }

  const handleSuccessAnswer = response => {
    const newProposalResource = proposalResource.map(el => {
      return el['@id'] === response['@id'] ? response : el
    })
    setProposalState.resource(newProposalResource)
  }

  const handleOpen = () => {
    setOpen(prevState => !prevState)
  }

  const handleConfirm = () => {
    history.push(routes().details.path.replace(':id', match.params.id))
  }

  return proposalResourceIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : proposalResourceFetchError ? (
    <NotFound />
  ) : (
    <Grid container className={localClasses.root}>
      <Grid item xs={8}>
        <ProjectScheduleTabs
          value={routes().leadtime.path.replace(':id', match.params.id)}
          classes={{ root: classes.tabs }}
          id={match.params.id}
        />
        <div className={localClasses.list_col}>
          <h4 className={localClasses.header}>
            Obecnie:{' '}
            {moment(projekt.data.currentProjectRealizationDateFrom).format(
              DATE_FORMAT
            )}{' '}
            -{' '}
            {moment(projekt.data.currentProjectRealizationDateTo).format(
              DATE_FORMAT
            )}
          </h4>
          {proposalResource?.map(item => (
            <div key={`${item.uuid}-date`}>
              <h5 className={localClasses.changesItem}>
                Zmiana {moment(item.createdAt).format(DATE_FORMAT)}:{' '}
                {moment(item.dateFrom).format(DATE_FORMAT)} -{' '}
                {moment(item.dateTo).format(DATE_FORMAT)} -{' '}
                <strong>
                  {translate(`T_SCHEDULE_CHANGE_STATUS_${item.status}`)}{' '}
                </strong>
              </h5>
              {item.message && <strong>{item.message}</strong>}
              {item.status === 'VERIFIED' && (
                <div style={{ marginTop: 0, marginBottom: 30 }}>
                  <strong>{item.question}</strong>
                  {item.answer ? (
                    <span style={{ fontSize: 14, display: 'block' }}>
                      Odpowiedź: {item.answer}
                    </span>
                  ) : (
                    <FormAnswer
                      url={item['@id'] + '/answer'}
                      method="PATCH"
                      iri={iri}
                      resource={{
                        answer: item.answer,
                      }}
                      definitionSchema={
                        definitions[
                          proposalSchema.resource_realization_date_answer
                            .definition
                        ]
                      }
                      customResourceSchema={
                        proposalSchema.resource_realization_date_answer
                      }
                      handleSuccess={handleSuccessAnswer}
                      submitButtonTitle={'Wyślij odpowiedź'}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={4} className={localClasses.form_col}>
        <h4 className={localClasses.header}>
          Wnioskuj o zmianę czasu realizacji
        </h4>
        <Form
          url={proposalSchema.endpoint_realization_date_requests}
          method="POST"
          iri={iri}
          resource={{
            proposal: iri,
            dateFrom: proposalResource?.[0]?.dateFrom,
            dateTo: proposalResource?.[0]?.dateTo,
            description: proposalResource?.[0]?.description,
          }}
          definitionSchema={
            definitions[proposalSchema.resource_realization_date.definition]
          }
          customResourceSchema={proposalSchema.resource_realization_date}
          handleSuccess={handleSuccess}
          submitButtonTitle={'Zgłoś zmianę'}
          disabled_XXX={
            proposalResource.length !== 0 &&
            ['PENDING', 'VERIFIED', 'ANSWERED'].includes(
              proposalResource?.[0].status
            )
          }
          disabled={true}
        />
        <CustomDialog
          handleToogle={handleOpen}
          open={open}
          title={'Harmonogram szczegółowy'}
          confirmedText={'Przejdź do harmonogramu'}
          confirmedButton={true}
          onConfirm={handleConfirm}
        >
          Zmiana czasu realizacji wymaga aktualizacji harmonogramu
          szczegółowego. Przejdź do harmonogramu, wprowadź i zatwierdź zmiany.
        </CustomDialog>
      </Grid>
    </Grid>
  )
}
