import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BudgetItemType } from './BudgetItemType'
import { generateBudgetCollectionRenderError } from 'pages/applicant/Proposals/_helpers/generateBudgetCollectionRenderError'
import { useBudgetCollectionTypeStyles } from './BudgetCollectionType.styles'
import { translate } from 'core/_helpers/translate'
import { Button } from '@material-ui/core'
import { BudgetItemNewType } from './BudgetItemNewType'
// import { isEnableBudgetMenageAdv } from '_helpers/isEnableBudgetMenageAdv'

export const BudgetChangesCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  isFormSubmitted,
  resource,
}) => {
  const [state, setState] = useState({
    values: value,
    errors: [],
    renderError: [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateBudgetCollectionRenderError(state.errors, true),
    }))
  }, [setState, state.isFormSubmitted])

  const subsidyAmountSum = resource.budgetChangeHistories.reduce(
    (sum, budget) => sum + (budget.subsidyAmount || 0),
    0
  )

  const subsidyAmountSumUpdated = state.values.reduce(
    (sum, budget) => sum + (budget.subsidyAmount || 0),
    0
  )

  const classes = useBudgetCollectionTypeStyles()

  const [newElement, setNewElement] = useState(false)

  const handleChangeNewElement = () => {
    setNewElement(prevState => !prevState)
  }
  const setNewElementInValues = newEl => {
    setState(prevState => ({
      ...prevState,
      values: [
        ...prevState.values,
        {
          budget: `/proposal_budgets/${newEl.uuid}`,
          title: newEl.title,
          titleOld: newEl.title,
          subsidyAmount: newEl.subsidyAmount,
          subsidyAmountLast: 0,
        },
      ],
    }))
  }

  // const [enableBudgetMenage, setEnableBudgetMenage] = useState(true)
  //
  // useEffect(() => {
  //   setEnableBudgetMenage(isEnableBudgetMenageAdv(value))
  // }, [value])

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className={classes.header}>
            <div>{translate('T_MODULE_PROPOSALS_BUDGET_TITLE')}</div>
            <div>Kwota dotowana</div>
            <div>Zmiana</div>
          </div>
          {state.values.map((item, index) => (
            <div className={classes.container} key={index}>
              <BudgetItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
                resource={resource.budgetChangeHistories?.[index]}
              />
            </div>
          ))}
          {newElement && (
            <BudgetItemNewType
              disabled={disabled}
              resource={resource}
              setState={setNewElementInValues}
            />
          )}
        </div>
      </div>
      {/*{enableBudgetMenage && (*/}
      <Button
        color={'secondary'}
        variant={'contained'}
        size={'small'}
        style={{ marginBottom: 15 }}
        onClick={handleChangeNewElement}
      >
        {translate('T_MODULE_PROPOSALS_BUDGET_ADD_NEW')}
      </Button>
      {/*)}*/}
      <div className={classes.header}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_BUDGET_SUM')}
        </div>
        <div
          className={
            Number(subsidyAmountSum).toFixed(2) !==
            Number(subsidyAmountSumUpdated).toFixed(2)
              ? classes.error
              : ''
          }
        >
          {Number(subsidyAmountSum).toFixed(2)}{' '}
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
        <div
          className={
            Number(subsidyAmountSum).toFixed(2) !==
            Number(subsidyAmountSumUpdated).toFixed(2)
              ? classes.error
              : ''
          }
        >
          {Number(subsidyAmountSumUpdated).toFixed(2)}{' '}
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>
      {Number(subsidyAmountSum).toFixed(2) !==
        Number(subsidyAmountSumUpdated).toFixed(2) && (
        <div className={classes.error}>
          Łączna kwota po zmianie musi być równa łącznej kwocie dotowanej (przed
          zmianą)
        </div>
      )}
    </>
  )
}

BudgetChangesCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
