const schema = {
  endpoint: '/contest_editions',
  resource: {
    definition: 'ContestEdition-contest_edition:read',
    properties: {
      title: {
        description: {
          text: 'T_EDITION_NAME',
        },
      },
      year: {
        description: {
          text: 'T_EDITION_YEAR',
        },
      },
      isFinished: {
        description: {
          text: 'Status',
        },
      },
      contestsCount: {
        description: {
          text: 'T_EDITION_CONTEST',
        },
      },
    },
  },
  resource_write: {
    definition: 'ContestEdition-contest_edition:write',
    properties: {
      names: {
        type: 'group',
        properties: {
          title: {
            type: 'string',
            validate: ['required'],
            column: 7,
            label: 'T_EDITION_NAME',
          },
          year: {
            type: 'integer',
            column: 5,
            label: 'T_EDITION_YEAR',
          },
          contractNumberNext: {
            type: 'integer',
            column: 5,
            label: 'Licznik dla następnego numeru umowy',
          },
        },
      },
      stat: {
        type: 'hidden',
        defaultValue: true,
      },
    },
  },
}

export default schema
