import React from 'react'
import PropTypes from 'prop-types'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: 30,
  },
  form: {
    width: 'fit-content',
  },
}))
export const BudgetForm = ({
  resource,
  definitionSchema,
  customResourceSchema,
  url,
  handleSuccess = null,
  disabled = false,
}) => {
  const localClasses = useStyles()

  return (
    <BlankForm
      url={url}
      method="POST"
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      submitButtonTitle={'Zgłoś zmianę'}
      submitButtonVariant="contained"
      handleSuccess={handleSuccess}
      submitButtonDisabled_XXX={disabled}
      submitButtonDisabled={true}
      classes={{
        root: localClasses.form,
      }}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <>
          <h3 className={localClasses.header}>Koszta:</h3>
          <FieldWrapper
            name="budgetChangeHistories"
            property={properties.budgetChangeHistories}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
            disabled_XXX={disabled}
            disabled={true}
          />

          <h3 className={localClasses.header}>Uzasadnij zmianę:</h3>
          <FieldWrapper
            label="(Pamiętaj, aby opisać wszystkie wprowadzone zmiany)"
            name="description"
            property={properties.description}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
            disabled_XXX={disabled}
            disabled={true}
          />
        </>
      )}
    </BlankForm>
  )
}

BudgetForm.propTypes = {
  resource: PropTypes.shape({
    budgets: PropTypes.arrayOf(
      PropTypes.shape({
        subsidyAmount: PropTypes.number.isRequired,
      })
    ),
    description_temp: PropTypes.string,
  }).isRequired,
  definitionSchema: PropTypes.object.isRequired,
  customResourceSchema: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func,
}
