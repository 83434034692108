import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { BudgetTabs } from 'pages/applicant/FinishedProposals/Budget/BudgetTabs'
import routes from '../routes'
import { useBudgetFetch } from './_helpers/useBudgetFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { DeclarationList } from './components/DeclarationList'
import { CustomDialog } from 'core/components/Dialog'
import { Button, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import schema, {
  STATUS_NOTREPORTED,
  STATUS_REPORTED,
} from '_schema/proposalQuarterlyDeclaration'
import { useProject } from '_helpers/useProject'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  btn: {
    marginTop: 20,
    marginLeft: 'auto',
    textAlign: 'right',
  },
  info: {
    marginTop: 20,
    textAlign: 'right',
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))

export const DeclarationBudget = ({ match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()

  const proposalUuid = match.params.id

  useProject(proposalUuid)

  const [declarationState, setDeclarationState] = useResourceState()

  const {
    resource: declarations,
    isFetching: declarationsIsFetching,
    fetchError: declarationsFetchError,
  } = declarationState

  useBudgetFetch(
    schema.endpoint_latest,
    proposalUuid,
    setDeclarationState.resource,
    setDeclarationState.fetchError
  )

  const [state, setState] = useState({
    open: false,
  })

  const handleToogle = useCallback(uuid => {
    setState(prevState => ({ ...prevState, open: uuid }))
  }, [])

  const reportDeclaration = uuid => {
    setDeclarationState.resource(
      declarations.map(declaration =>
        declaration.uuid === uuid
          ? { ...declaration, status: 'REPORTED' }
          : declaration
      )
    )
  }

  const [isFetching, setIsFetching] = useState(false)

  const handleReport = quarterUuid => () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_report.replace(':id', quarterUuid),
      'PATCH',
      { body: JSON.stringify({}) },
      () => {
        reportDeclaration(quarterUuid)

        notification('success', 'Wysłano deklarację', 'T_FORM_SUCCESS')
        setIsFetching(false)
        handleToogle()
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setIsFetching(false)
      }
    )
  }

  return declarationsIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : declarationsFetchError ? (
    <NotFound />
  ) : (
    <>
      <BudgetTabs
        value={routes().declaration.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        {declarations.map((declaration, index) => (
          <div key={declaration.uuid} style={{ marginBottom: 50 }}>
            <DeclarationList
              items={declaration.quarterlyDeclarationItem}
              title={`Wydatki poniesione w projekcie na dzień ${getDateLastDay(
                declaration.quarterNum
              )}${getYear(declaration.quarterId)}`}
              status={declaration.status}
            />
            {declaration.status === STATUS_NOTREPORTED ? (
              <div>
                <div className={localClasses.btn}>
                  <Typography variant={'body2'} color={'primary'}>
                    Możliwość deklaracji wydatków od{' '}
                    {isOneMonthAfterQuartet(
                      declaration.quarterId,
                      declaration.quarterNum
                    ).dateStartMoment.format(DATE_FORMAT)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => handleToogle(declaration.uuid)}
                    disabled_XXX={
                      !(
                        (index === declarations.length - 1 ||
                          declarations[index + 1].status !== 'NOTREPORTED') &&
                        isOneMonthAfterQuartet(
                          declaration.quarterId,
                          declaration.quarterNum
                        ).isActive
                      )
                    }
                    disabled={true}
                  >
                    Deklaruję kwotę wydatków na dzień{' '}
                    {getDateLastDay(declaration.quarterNum)}
                    {getYear(declaration.quarterId)}
                  </Button>
                </div>
                <CustomDialog
                  handleToogle={() => handleToogle('')}
                  open={state.open === declaration.uuid}
                  title={'Potwierdzenie'}
                  component={() => (
                    <Typography variant={'body1'} color={'primary'}>
                      {`${translate(
                        'Czy potwierdzasz, że chcesz zadeklarować kwotę wydatków na dzień '
                      )}${getDateLastDay(declaration.quarterNum)}
                    ${getYear(declaration.quarterId)}`}
                    </Typography>
                  )}
                  confirmedButton={true}
                  isFetching={isFetching}
                  onConfirm={handleReport(declaration.uuid)}
                />
              </div>
            ) : (
              <div className={localClasses.info}>
                {declaration.status === STATUS_REPORTED
                  ? 'Oczekuje na akceptację'
                  : 'Raport zaakceptowany'}
              </div>
            )}
          </div>
        ))}
      </Paper>
    </>
  )
}

// const getDateRange = quarterNum => {
//   switch (quarterNum) {
//     case 1:
//       return 'od 1 stycznia do 31 marca'
//     case 2:
//       return 'od 1 kwietnia do 30 czerwca'
//     case 3:
//       return 'od 1 lipca do 30 września'
//     case 4:
//     default:
//       return 'od 1 października do 31 grudnia'
//   }
// }
//
// const getDateFirstDay = quarterNum => {
//   switch (quarterNum) {
//     case 1:
//       return '01.01.'
//     case 2:
//       return '01.04.'
//     case 3:
//       return '01.07.'
//     case 4:
//     default:
//       return '01.10.'
//   }
// }

const getDateLastDay = quarterNum => {
  switch (quarterNum) {
    case 1:
      return '31.03.'
    case 2:
      return '30.06.'
    case 3:
      return '30.09.'
    case 4:
    default:
      return '31.12.'
  }
}

const getYear = quarterId => {
  return quarterId.split('-')[0]
}

const isOneMonthAfterQuartet = (quarterId, quarterNum) => {
  const year = getYear(quarterId)
  const dateStart = new Date(Number(year), quarterNum * 3, 1)
  const dateEnd = new Date(Number(year), quarterNum * 3 + 1, 0)
  const dateStartMoment = moment(dateStart)
  const dateEndMoment = moment(dateEnd)
  return {
    isActive: moment().isSameOrAfter(dateStartMoment, 'days'),
    dateStartMoment: dateStartMoment,
    dateEndMoment: dateEndMoment,
  }
}
