import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Step, StepButton, Stepper } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { contractSteps } from 'pages/applicant/AppliedProposals/_helpers/contractSteps'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 90,
  },
  disabled: {
    '& svg': {
      color: `${theme.palette.disabled} !important`,
    },
    '& span': {
      color: `${theme.palette.disabled} !important`,
    },
  },
}))

export const ContractSteps = ({
  isFillStepDisabled,
  isConfirmStepDisabled,
  isUploadStepDisabled,
  activeStep,
  handleStepChange,
}) => {
  const classes = useStyles()

  return (
    <Stepper
      alternativeLabel
      nonLinear
      activeStep={activeStep}
      className={classes.root}
    >
      <Step key={contractSteps.FILL} disabled={isFillStepDisabled}>
        <StepButton
          onClick={handleStepChange(contractSteps.FILL)}
          id="step-button-fill"
          aria-controls="step-fill"
          classes={{
            root: isFillStepDisabled && classes.disabled,
          }}
        >
          {translate('T_MODULE_PROPOSALS_CONTRACT_STEP_FILL')}
        </StepButton>
      </Step>
      <Step key={contractSteps.CONFIRM} disabled={isConfirmStepDisabled}>
        <StepButton
          onClick={handleStepChange(contractSteps.CONFIRM)}
          id="step-button-confirm"
          aria-controls="step-confirm"
          classes={{
            root: isConfirmStepDisabled && classes.disabled,
          }}
        >
          {translate('T_MODULE_PROPOSALS_CONTRACT_STEP_CONFIRM')}
        </StepButton>
      </Step>
      <Step key={contractSteps.UPLOAD} disabled={isUploadStepDisabled}>
        <StepButton
          onClick={handleStepChange(contractSteps.UPLOAD)}
          id="step-button-upload"
          aria-controls="step-upload"
          classes={{
            root: isUploadStepDisabled && classes.disabled,
          }}
        >
          {translate('T_MODULE_PROPOSALS_CONTRACT_STEP_UPLOAD')}
        </StepButton>
      </Step>
    </Stepper>
  )
}

ContractSteps.propTypes = {
  isFillStepDisabled: PropTypes.bool,
  isConfirmStepDisabled: PropTypes.bool,
  activeStep: PropTypes.number.isRequired,
  handleStepChange: PropTypes.func.isRequired,
}
