import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ArrowBackOutlined } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useReportState } from 'pages/applicant/FinishedProposals/FinalReport/_helpers/useReportState'
import { useReportFetch } from 'pages/applicant/FinishedProposals/FinalReport/_helpers/useReportFetch'
import { useCollectionTabsStyles } from 'components/applicant/collection_styles/CollectionTabs.styles'
import { Link } from 'react-router-dom'
import { FieldWrapper } from 'components/applicant/form'
import {
  ReportForm,
  Sidebar,
} from 'pages/applicant/FinishedProposals/FinalReport/components'
import routes from '../routes'
import schema, {
  CONTENT_REPORT_STATUS_DRAFT,
  CONTENT_REPORT_STATUS_RETURNED,
} from '_schema/proposal'

const useStyles = makeStyles({
  root: {
    paddingTop: 16,
    paddingLeft: 45,
    paddingRight: 32,
  },
  list_col: {
    backgroundColor: 'white',
    marginLeft: 45,
    marginRight: 100,
    marginTop: 30,
    padding: 30,
  },
  form_col: {
    paddingTop: 60,
    paddingBottom: 60,
    paddingRight: 70,
    paddingLeft: 70,
    backgroundColor: 'white',
  },
})

export const PartialReport = ({ schema: definitions, match }) => {
  const proposalUuid = match.params.id

  const [reportState, setReportState] = useReportState('partialReport')

  const iri = `${schema.endpoint}/${proposalUuid}`

  const { proposal, contest, isFetching, fetchError } = reportState

  useReportFetch(
    iri,
    setReportState.proposal,
    setReportState.contest,
    setReportState.form,
    setReportState.fetchError,
    setReportState.formFetched,
    'partialReport'
  )

  const [formButtonsRef, setFormButtonsRef] = useState(null)

  const onFormButtonsRef = element => {
    setFormButtonsRef(element)
  }

  const handleApplySuccess = response => {
    setReportState.proposal({
      ...proposal,
      ...response,
    })
  }

  const localClasses = useStyles()
  const defaultClasses = useCollectionTabsStyles()

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : !proposal.isPartialReportRequired || fetchError ? (
    <NotFound />
  ) : (
    <Grid container>
      <Grid item xs={8}>
        <div className={localClasses.root}>
          <div className={defaultClasses.bar}>
            <Link
              to={routes().view.path.replace(':id', match.params.id)}
              className={defaultClasses.back_to_list}
            >
              <ArrowBackOutlined /> Zakończone
            </Link>
            <h2 className={defaultClasses.title}>Raport częściowy</h2>
          </div>
        </div>
        <div className={localClasses.list_col}>
          <ReportForm
            url={schema.endpoint_partialReport_edit.replace(
              ':id',
              proposal.uuid
            )}
            resource={proposal}
            definitionSchema={
              definitions[schema.resource_partialReport_edit.definition]
            }
            customResourceSchema={schema.resource_partialReport_edit}
            handleApplySuccess={handleApplySuccess}
            buttonContainerRef={formButtonsRef}
            /*disabled_XXX={
              ![
                CONTENT_REPORT_STATUS_DRAFT,
                CONTENT_REPORT_STATUS_RETURNED,
              ].includes(proposal.partialReportStatus)
            }*/
            disabled={true}
            type="partialReport"
          >
            {({ state, properties, resource, setValue, setError }) => (
              <>
                {!!contest.partialReportForm?.formSections?.length && (
                  <FieldWrapper
                    name="partialReportFormValues"
                    property={properties.partialReportFormValues}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    contest={contest}
                    proposal={resource}
                    sectionsMapping={contest.partialReportForm.formSections}
                    /*disabled_XXX={
                      ![
                        CONTENT_REPORT_STATUS_DRAFT,
                        CONTENT_REPORT_STATUS_RETURNED,
                      ].includes(proposal.partialReportStatus)
                    }*/
                    disabled={true}
                  />
                )}
              </>
            )}
          </ReportForm>
        </div>
      </Grid>
      <Grid item xs={4} className={localClasses.form_col}>
        <Sidebar
          btnRef={onFormButtonsRef}
          proposal={proposal}
          setProposal={setReportState.proposal}
          definitions={definitions}
          type="partialReport"
        />
      </Grid>
    </Grid>
  )
}
