const schema = {
  endpoint_category: '/faq_categories',
  endpoint_item: '/faq_items',
  resource_category: {
    definition: 'FaqCategory-faq_category:read',
    properties: {
      title: {
        type: 'string',
        validate: ['required'],
        column: 7,
        width: '600px',
        label: 'Tytuł kategorii',
      },
    },
  },
  resource_question: {
    definition: 'FaqItem-faq_item:write',
    properties: {
      question: {
        type: 'string',
        validate: ['required'],
        width: '100%',
        label: 'Pytanie',
      },
      answer: {
        type: 'tinymce',
        validate: ['required'],
        width: '100%',
        label: 'Odpowiedź',
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: '/photos',
        validate: ['maxSize'],
      },
      file: {
        type: 'file',
        width: '100%',
        endpoint: '/files',
        label: 'Dokument',
      },
    },
  },
}

export default schema
