import * as React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
} from '@material-ui/core'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'
import { downloadFile } from '_helpers/downloadFile'
import {
  CONTENT_REPORT_STATUS_DRAFT,
  CONTENT_REPORT_STATUS_PENDING,
  CONTENT_REPORT_STATUS_RETURNED,
  CONTENT_REPORT_STATUS_REJECTED,
} from '_schema/proposal'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/proposal'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    '& .MuiStepper-root': {
      marginBottom: 0,
    },
  },
  buttons: {
    display: 'flex',
    columnGap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  error: {
    color: theme.palette.error.main,
  },
  returned: {
    marginBottom: 15,
    color: theme.palette.error.main,
  },
}))

export const Sidebar = ({
  btnRef,
  proposal,
  setProposal,
  definitions,
  type = 'finalReport',
}) => {
  const classes = useStyles()

  const handleDownload = () => {
    downloadFile(
      schema[`endpoint_${type}_download`].replace(':id', proposal.uuid),
      `raport-m-${proposal.contractNumber || proposal.uuid}.pdf`
    )
  }

  const handleUploadSuccess = response => {
    setProposal({ ...proposal, ...response })
    setActiveStep(step => step + 1)
  }

  const steps = [
    {
      label: 'Wypełnij formularz i złóż raport',
      description: <div className={classes.buttons} ref={btnRef}></div>,
    },
    {
      label: 'Pobierz pdf raportu, wydrukuj, a następnie podpisz.',
      description: (
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleDownload}
          disabled={proposal[`${type}Status`] !== CONTENT_REPORT_STATUS_PENDING}
        >
          pobierz raport {type === 'finalReport' ? 'końcowy' : 'częściowy'} jako
          pdf
        </Button>
      ),
    },
    {
      label:
        'Zeskanowany raport wgraj do systemu, a oryginał wyślij pocztą na adres fundacji',
      description: (
        <>
          <BlankForm
            url={schema[`endpoint_${type}_upload_file`].replace(
              ':id',
              proposal.uuid
            )}
            method="PUT"
            resource={proposal}
            definitionSchema={
              definitions[schema[`resource_${type}_upload`].definition]
            }
            customResourceSchema={schema[`resource_${type}_upload`]}
            handleSuccess={handleUploadSuccess}
            onlyPassedProperties={true}
            submitButtonTitle={translate('Wyślij podpisany skan')}
            submitButtonVariant="contained"
            submitButtonDisabled={true}
            submitButtonSize="small"
          >
            {({ state, properties, resource, setValue, setError }) => (
              <div>
                <FieldWrapper
                  name={`${type}File`}
                  label={translate('Podpisany skan')}
                  property={properties[`${type}File`]}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                />
              </div>
            )}
          </BlankForm>
        </>
      ),
    },
  ]

  const [activeStep, setActiveStep] = React.useState(
    [CONTENT_REPORT_STATUS_DRAFT, CONTENT_REPORT_STATUS_RETURNED].includes(
      proposal[`${type}Status`]
    )
      ? 0
      : proposal[`${type}Status`] === CONTENT_REPORT_STATUS_PENDING &&
        !proposal[`${type}SigningDate`]
      ? 1
      : 3
  )

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      {proposal[`${type}ReturnExplanation`] &&
        [
          CONTENT_REPORT_STATUS_RETURNED,
          CONTENT_REPORT_STATUS_REJECTED,
        ].includes(proposal[`${type}Status`]) && (
          <div className={classes.returned}>
            {proposal[`${type}ReturnExplanation`]}
          </div>
        )}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Ostatni krok</Typography>
                ) : null
              }
            >
              <Typography variant="h3">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              {step.description}
              <div className={classes.actionsContainer}>
                <div>
                  {index < steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleNext}
                      disabled={
                        index === 0
                          ? [
                              CONTENT_REPORT_STATUS_DRAFT,
                              CONTENT_REPORT_STATUS_RETURNED,
                            ].includes(proposal[`${type}Status`])
                          : false
                      }
                      className={classes.button}
                    >
                      Dalej
                    </Button>
                  )}
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Wstecz
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Button
          type="submit"
          variant={'outlined'}
          color="secondary"
          size="small"
          style={{
            marginTop: 20,
          }}
          onClick={handleDownload}
        >
          pobierz raport {type === 'finalReport' ? 'końcowy' : 'częściowy'} jako
          pdf
        </Button>
      )}

      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography
            variant="h3"
            className={
              proposal[`${type}Status`] === CONTENT_REPORT_STATUS_REJECTED &&
              classes.error
            }
          >
            {translate(
              `T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_${
                proposal[`${type}Status`]
              }`
            )}
          </Typography>
          {proposal[`${type}SigningDate`] &&
            `Wysłałeś: ${moment(proposal[`${type}SigningDate`]).format(
              DATE_FORMAT
            )}`}
        </Paper>
      )}
    </div>
  )
}
