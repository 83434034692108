import React from 'react'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { TableList } from './components/TableList'
import { useCollectionTabsStyles } from 'components/applicant/collection_styles/CollectionTabs.styles'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { useProposalRelationCollectionFetch } from './_helpers/useProposalRelationCollectionFetch'
import { Link } from 'react-router-dom'
import { ArrowBackOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import schema from '_schema/proposalRelation'
import routes from './../routes'

const useStyles = makeStyles({
  root: {
    paddingTop: 16,
    paddingLeft: 45,
    paddingRight: 32,
  },
})

export const Relations = ({ match }) => {
  const classes = useCollectionContainerStyles()
  const defaultClasses = useCollectionTabsStyles()
  const localClasses = useStyles()

  const [state, setState] = useProposalRelationCollectionFetch(
    schema.endpoint,
    match.params.id
  )

  return (
    <>
      <div className={localClasses.root}>
        <div className={defaultClasses.bar}>
          <Link
            to={routes().view.path.replace(':id', match.params.id)}
            className={defaultClasses.back_to_list}
          >
            <ArrowBackOutlined /> Zakończone
          </Link>
          <h2 className={defaultClasses.title}>Relacje</h2>
        </div>
      </div>
      <Paper classes={{ root: classes.paper_wrapper }}>
        {state.init && <Loader align="center" />}
        <TableList
          items={state.items}
          setState={setState}
          title="Lista relacji"
          id={match.params.id}
        />
      </Paper>
    </>
  )
}
