import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import schema, {
  ACCEPTATION_STATUS_SUBSIDIZED,
  REALIZATION_STATUS_FINISHED,
  REALIZATION_STATUS_INPROGRESS,
  REALIZATION_STATUS_REJECTED,
  REALIZATION_STATUS_CANCELED,
  RECOMMENDATION_STATUS_YES,
  MONITORING_RECOMMENDATION_YES,
  BUDGET_CHANGE_STATUS_PENDING,
  BUDGET_CHANGE_STATUS_ANSWERED,
  BUDGET_CHANGE_STATUS_NEW,
  SCHEDULE_CHANGE_STATUS_NEW,
  SCHEDULE_CHANGE_STATUS_PENDING,
  SCHEDULE_CHANGE_STATUS_ANSWERED,
  RELATION_STATUS_PENDING,
  QUARTERLY_DECLARATION_STATUS_REPORTED,
} from '_schema/proposal'
import schemaContest from '_schema/contests'
import routes from './routes'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { translate } from 'core/_helpers/translate'
import {
  APPLICATION_STATUS_DRAFT,
  APPLICATION_STATUS_APPLIED,
  RATED_YES,
  TRAINING_YES,
  TRAINING_NO,
  CONTRACT_YES,
} from '_schema/proposal'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { Paper } from 'core/components/Paper'
import { makeStyles } from '@material-ui/styles'
import { TranslateCell } from 'core/components/table/cells/TranslateCell'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  columnHeaderFilter: {
    height: 120,
    alignItems: 'flex-end',
  },
}))

export const ProposalCollection = ({ match }) => {
  const collection = useSelector(state => state.collection[match.params.id])
  const [firstLoad, setFirstLoad] = useState(false)
  const filtersDef = useMemo(
    () => ({
      all: {
        label: 'Wszystkie',
        value: 'all',
        title: 'all',
      },
      [APPLICATION_STATUS_DRAFT]: {
        name: 'applicationStatus',
        value: APPLICATION_STATUS_DRAFT,
        title: 'applicationStatusDraft',
        label: 'Szkice',
      },
      [APPLICATION_STATUS_APPLIED]: {
        name: 'applicationStatus',
        value: APPLICATION_STATUS_APPLIED,
        title: 'applicationStatusApplied',
        label: 'Złożone',
      },
      [RATED_YES]: {
        name: 'rated',
        value: RATED_YES,
        title: 'rated',
        label: 'Oceny',
      },
      [TRAINING_YES]: {
        name: 'training',
        value: TRAINING_YES,
        title: 'Training',
        label: 'Szkolenia',
      },
      [TRAINING_NO]: {
        name: 'training',
        value: TRAINING_NO,
        title: 'Training',
        label: 'Nowe ankiety',
      },
      [CONTRACT_YES]: {
        name: 'contract',
        value: CONTRACT_YES,
        title: 'Contract',
        label: 'Umowy',
      },
      [ACCEPTATION_STATUS_SUBSIDIZED]: {
        name: 'acceptationStatus',
        value: ACCEPTATION_STATUS_SUBSIDIZED,
        title: 'acceptationStatusSubsized',
        label: 'Dotowane',
      },
      [RECOMMENDATION_STATUS_YES]: {
        name: 'recommendationStatus',
        value: RECOMMENDATION_STATUS_YES,
        title: 'recommendationStatusYes',
        label: 'Rekomendowane',
      },
      [MONITORING_RECOMMENDATION_YES]: {
        name: 'monitoringRecommendation',
        value: 1,
        title: 'monitoringRecommendationYes',
        label: 'Monitorowane',
      },
      [REALIZATION_STATUS_INPROGRESS]: {
        name: 'realizationStatus',
        value: REALIZATION_STATUS_INPROGRESS,
        title: 'realizationStatusInprogress',
        label: 'W realizacji',
      },
      [REALIZATION_STATUS_FINISHED]: {
        name: 'listTab',
        value: 'TAB_FINISHED',
        title: 'realizationStatusFinished',
        label: 'Zakończone',
      },
      [REALIZATION_STATUS_CANCELED]: {
        name: 'listTab',
        value: 'TAB_CANCELED',
        title: 'realizationStatusCancelled',
        label: 'Anulowane',
      },
      [REALIZATION_STATUS_REJECTED]: {
        name: 'listTab',
        value: 'TAB_REJECTED',
        title: 'realizationStatusRejected',
        label: 'Odrzucone',
      },
      [BUDGET_CHANGE_STATUS_PENDING]: {
        name: 'budgetChangeStatus',
        value: [BUDGET_CHANGE_STATUS_NEW, BUDGET_CHANGE_STATUS_ANSWERED],
        title: 'budgetChangeStatusPending',
        label: 'Nowa zmiana budżetu',
      },
      SCHEDULE_CHANGE_STATUS_PENDING: {
        name: 'scheduleChangeStatus',
        value: [
          SCHEDULE_CHANGE_STATUS_NEW,
          SCHEDULE_CHANGE_STATUS_PENDING,
          SCHEDULE_CHANGE_STATUS_ANSWERED,
        ],
        title: 'scheduleChangeStatusPending',
        label: 'Nowa zmiana harmonogramu',
      },
      RELATION_STATUS_PENDING: {
        name: 'relationStatus',
        value: [RELATION_STATUS_PENDING],
        title: 'relationStatusPending',
        label: 'Nowa relacja',
      },
      QUARTERLY_DECLARATION_STATUS_REPORTED: {
        name: 'quarterlyDeclarationStatus',
        value: [QUARTERLY_DECLARATION_STATUS_REPORTED],
        title: 'quarterlyDeclarationStatusReported',
        label: 'Nowa deklaracja kwartalna',
      },
    }),
    []
  )

  const iri = `${schemaContest.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const columns = useMemo(
    () => [
      {
        ...standard('proposalNumber', true),
        sortable: true,
        filterable: false,
        // width: '20%',
      },
      {
        ...standard('subsidyNumber'),
        sortable: false,
        filterable: false,
        // width: '20%',
      },
      {
        ...standard('title', true),
        sortable: true,
        filterable: false,
        width: '20%',
      },
      {
        ...standard('organizationName', true),
        sortable: true,
        filterable: false,
        width: '20%',
      },
      {
        accessor: 'applicationStatus',
        Cell: TranslateCell,
        pre: 'T_MODULE_PROPOSALS_APPLICATION_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'formalRateStatus',
        Cell: TranslateCell,
        pre: 'T_EVENT_RATE_FORMAL_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        ...standard('internalRatePoints'),
        sortable: true,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('substantiveRatePoints'),
        sortable: true,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        accessor: 'visibleForExperts',
        Cell: TranslateCell,
        pre: 'T',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'acceptationStatus',
        Cell: TranslateCell,
        pre: 'T_ACCEPTATION_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'recommendationStatus',
        Cell: TranslateCell,
        pre: 'T_RECOMMENDATION_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'contractStatus',
        Cell: TranslateCell,
        pre: 'T_MODULE_PROPOSAL_CONTRACT_STATUS',
        sortable: false,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'realizationStatus',
        Cell: TranslateCell,
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'finalReportStatus',
        Cell: TranslateCell,
        pre: 'T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'budgetChangeStatus',
        Cell: TranslateCell,
        pre: 'T_BUDGET_CHANGE_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'scheduleChangeStatus',
        Cell: TranslateCell,
        pre: 'T_SCHEDULE_CHANGE_STATUS',
        sortable: true,
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        accessor: 'applicantEmail',
        ...standard('applicantEmail', true),
        filterable: false,
        // width: '4%',
        // vertical: true,
      },
      {
        ...standard('city', false, false),
        sortable: false,
        filterable: false,
        // width: '20%',
      },
    ],
    []
  )

  const classess = useStyles()

  const [reload, setReload] = useState(false)

  const handleSetReload = useCallback(() => {
    setReload(state => !state)
  }, [])

  const [tab, setTab] = useState('all')

  useEffect(() => {
    if (!firstLoad) {
      if (collection !== undefined && collection.filters !== undefined) {
        setFirstLoad(true)
        const tabElement = Object.keys(filtersDef).find(k =>
          Object.keys(collection.filters).find(
            f =>
              f === filtersDef[k].name &&
              collection.filters[f].value === filtersDef[k].value
          )
        )
        if (tabElement !== undefined) {
          setTab(tabElement)
        }
      }
    }
  }, [firstLoad, collection, filtersDef])

  useEffect(() => {
    console.log('tab', tab)
  }, [tab])
  const [filters, setFilters] = useState(
    collection !== undefined && collection.filters !== undefined
      ? collection.filters
      : {}
  )

  const handleChangeTab = (event, value) => {
    setTab(value)
    setFilters(filtersDef[value])
    setReload(true)
  }

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <>
      <CollectionHeader hideButton={true} hideButtonBack={true}>
        {resource?.contestEdition?.title} {resource.title}
      </CollectionHeader>
      <StyledTabs
        value={tab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        className={classess.tabs}
      >
        {Object.keys(filtersDef).map((key, i) => {
          return (
            <StyledTab
              key={i}
              label={translate(filtersDef[key].label)}
              value={key}
            />
          )
        })}
      </StyledTabs>
      <Paper withPadding={false}>
        <div style={{ paddingTop: 0 }}>
          <SchemableCollection
            component={CollectionTable}
            path={`paths.${schema.endpoint}.get`}
            endpoint={schema.endpoint}
            customResourceSchema={schema.resource_proposal_contest}
            columns={columns}
            autoWidth={false}
            filterable={true}
            filterInline={true}
            sortable={true}
            numerable={false}
            downloadCSV={true}
            downloadCSVFileTitle={'Lista-projektow'}
            customFilters={[
              {
                name: 'contest.uuid',
                value: match.params.id,
                title: 'contest',
              },
              filters,
            ]}
            resetable={false}
            paginable={true}
            definitionSchema={schema.resource_proposal_contest}
            isRowLinkable={true}
            editPath={routes().item.path}
            storeCollectionId={`${match.params.id}`}
            reload={reload}
            setReload={handleSetReload}
            classes={classess}
            saveIndexInPath={true}
          />
        </div>
      </Paper>
    </>
  )
}
