import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { SelectType } from 'components/applicant/form/fields/SelectType'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  label: {
    marginBottom: 5,
  },
  help: {
    marginLeft: 25,
    color: theme.palette.disabledDark,
  },
  input: {
    width: 350,
  },
}))

export const ChoicesSelectBlock = ({
  data,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled,
}) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.CHOICES_SELECT, data),
    [data]
  )

  const validators = useMemo(
    () => ({
      input: mapping.input.required ? ['required'] : [],
    }),
    [mapping]
  )

  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div>
        <div className={classes.label}>{mapping.input.label}</div>
        <div>
          <SelectType
            name="input"
            choices={mapping.input.choices}
            defaultValue={mapping.input.defaultValue}
            value={values?.input}
            error={errors?.input}
            renderError={globalRenderError && renderError?.input}
            disabled={disabled}
            validators={validators.input}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input,
            }}
          />
        </div>
      </div>
      <div className={classes.help}>{mapping.input.help}</div>
    </div>
  )
}

ChoicesSelectBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
